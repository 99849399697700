import React, { useState } from "react";
import "./Carousel.css";
import 'react-html5video/dist/styles.css'
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { slideUpVariants } from "../carewell component/animation";
import { motion } from "framer-motion";


export const Carousel = ({ data }) => {
  const [slide, setSlide] = useState(0);

  const nextSlide = () => {
    setSlide(slide === data.length - 1 ? 0 : slide + 1);
  };

  const prevSlide = () => {
    setSlide(slide === 0 ? data.length - 1 : slide - 1);
  };

  return (
    <div className="carousel xl:h-[70vh] lg:h-[60vh] h-[40vh] object-cover object-center w-full transition ease-in-out duration-300 delay-100">
      <IoIosArrowDropleft onClick={prevSlide} className="arrow arrow-left" />
      {data.map((item, idx) => {
        return (
          <motion.img variants={slideUpVariants} initial='hidden' whileInView='visible' transition={{duration:0.5, delay: idx * 0.5}}
            src={item}
            // alt={item.alt}
            key={idx}
            className={slide === idx ? "slide" : "slide slide-hidden"}
          />
        );
      })}
      <IoIosArrowDropright
        onClick={nextSlide}
        className="arrow arrow-right"
      />
      <span className="indicators">
        {data.map((_, idx) => {
          return (
            <button
              key={idx}
              className={
                slide === idx ? "indicator" : "indicator indicator-inactive"
              }
              onClick={() => setSlide(idx)}
            ></button>
          );
        })}
      </span>
    </div>
  );
};