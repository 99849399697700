import React from 'react'
import {
    KeyframeOptions,
    animate,
    useInView,
    useIsomorphicLayoutEffect,
  } from "framer-motion";
  import { useRef } from "react";

const AnimatedCounter = ({
    
        from,
        to,
        KeyframeOptions,
      }) => {
        const ref = useRef(null);
        const inView = useInView(ref, { once: true });
      
        useIsomorphicLayoutEffect(() => {
          const element = ref.current;
      
          if (!element) return;
          if (!inView) return;
      
          // Set initial value
          element.textContent = (from);
      
          // If reduced motion is enabled in system's preferences
          if (window.matchMedia("(prefers-reduced-motion)").matches) {
            element.textContent = (to);
            return;
          }
      
          const controls = animate(from, to, {
            duration: 10,
            ease: "easeOut",
            ...KeyframeOptions,
            onUpdate(value) {
              element.textContent = value.toFixed(0);
            },
          });
      
          // Cancel on unmount
          return () => {
            controls.stop();
          };
        }, [ref, inView, from, to]);
      
        return <span ref={ref} />;
      };
  

export default AnimatedCounter