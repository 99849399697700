import { motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { MdLocalPhone, MdEmail } from "react-icons/md";
import { ImLocation2 } from "react-icons/im";
import {slideUpVariants, zoomInVariants} from '../assets/animation'
import { FaWhatsapp } from "react-icons/fa6";
import emailjs from '@emailjs/browser'

const Contact = () => {  
    useEffect (() => {
        document.title = 'Contact Us | Darobet Nigeria Limited'
      },[]) 
  const [error, setError] = useState(null)
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [name, setName] = useState('')
  const [message, setMessage] = useState('')
  const [phone, setPhone] = useState('')
  const [success, setSuccess] = useState(null)
  const form = useRef();


  const handleSubmit = (e) => {
    e.preventDefault()
    if(email === '' || name === '' || message === '' || phone === '' || subject === ''){
        setError('All fields are mandatory')
    }else{

    emailjs
      .sendForm(
        "service_67l0dl8",
        "template_oyyfo9p",
        form.current,
        {
          publicKey: 'B9s6oEG5bo-nAfp9H'
        }
      )
      .then(
        () => {
          setEmail('')
          setMessage('')
          setPhone('')
          setSubject('')
          setName('')
        //   console.log("message sent");
          setSuccess('Message Sent Successfully')
        },
        (error) => {
          console.log(error.text);
        }
      );
}}
  return (
    <motion.div initial ={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} transition={{duration:2}} id='contact'>
        <div className='flex items-center justify-center xl:h-[70vh] lg:h-[60vh] h-[50vh] flex-col' id='info_bg'>
            <h1 variants={zoomInVariants} initial='hidden' whileInView='visible'  className='text-2xl xl:text-6xl lg:text-6xl md:text-5xl capitalize pb-4 text-white font-heading'>Contact informations</h1>
            <motion.p  variants={zoomInVariants} initial='hidden' whileInView='visible' className='xl:w-[50%] lg:w-[50%] text-center text-clip px-[1rem] xl:text-[1.1rem] lg:text-[1.1rem] text-[14px] xl:px-0 lg:px-0 text-white'>Darobet Nigeria Limited welcomes opportunities for growth and collaboration. Connect with us to explore how we can bring your vision to life and contribute to the evolution of vibrant communities.</motion.p>
        </div>
        <div className='flex items-start justify-center gap-[3rem] w-full xl:flex-nowrap lg:flex-nowrap flex-wrap bg-gray-100 py-[4rem]'>
            <div className='flex items-center gap-5 xl:w-[30%] lg:w-[30%] flex-col'>
                <motion.div variants={slideUpVariants} initial='hidden' whileInView='visible' className='flex items-center gap-5 p-5 w-full' id='contact_bg'>
                    <div className='p-3 rounded-full bg-bg'>
                        <MdLocalPhone className='text-[18px] text-white'/>
                    </div>
                    <div>
                        <h1 className='text-color font-semibold'>Phone Number</h1>
                        <p>+234-803-3356733</p>
                        <p>+234-805-5550436</p>
                    </div>
                </motion.div>
                <motion.div variants={slideUpVariants} initial='hidden' whileInView='visible' className='flex items-center gap-5 p-5 w-full' id='contact_bg'>
                    <div className='p-3 rounded-full bg-bg'>
                        <MdEmail className='text-[18px] text-white'/>
                    </div>
                    <div>
                        <h1 className='text-color font-semibold'>Email address</h1>
                        <p className='hover:text-cyan-400 underline'><a href="mailto:aosuporu@yahoo.com">aosuporu@yahoo.com</a></p>
                        <p className='hover:text-cyan-400 underline'><a href="mailto:darobetinternational@gmail.com">darobetinternational@gmail.com</a></p>
                    </div>
                </motion.div>

                <motion.div variants={slideUpVariants} initial='hidden' whileInView='visible' className='flex items-center gap-5 p-5 w-full' id='contact_bg'>
                    <div className='p-3 rounded-full bg-bg'>
                        <ImLocation2 className='text-[18px] text-white'/>
                    </div>
                    <div>
                        <h1 className='text-color font-semibold'>Location</h1>
                        <p className='text-[15px]'>Plot 325 Kingsley Ebenyi Close, Apo, Gudu District Abuja. P.O.Box 3978, Garki Abuja.</p>
                        <p className='text-[15px]'>Canada.</p>
                    </div>
                </motion.div>
                
            </div>
            <motion.div variants={slideUpVariants} initial='hidden' whileInView='visible' className='xl:w-[40%] lg:w-[40%] p-8' id='contact_bg'>
                <h1 className='text-xl pb-4 text-color font-semibold'>Send Message</h1>
                <p>Connect with us to explore how we can bring your vision to life and contribute to the evolution of vibrant communities.</p>
                <motion.form variants={slideUpVariants} initial='hidden' whileInView='visible' action="" className='my-10' ref={form}>
                    <div className='pb-3 flex items-center justify-between flex-wrap w-full transition ease-in-out duration-300 delay-100'>
                        <input type="text" name='subject' value={name} placeholder='Your name' className={`xl:mb-0 lg:mb-0 mb-2 p-2 border-2 focus:outline-none focus:border-color rounded-[4px] xl:w-[49%] lg:w-[49%] w-full transition ease-in-out duration-300 delay-100 ${error ? 'border-[#FF0000]' : ''}`} onChange={(e) => setName(e.target.value)}/>
                        <input type="text" name='from_name' id='email' value={email} placeholder='Email address' className={`p-2 border-2 focus:outline-none focus:border-color rounded-[4px] xl:w-[49%] lg:w-[49%] w-full transition ease-in-out duration-300 delay-100 ${error ? 'border-[#FF0000]' : ''}`} onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                    <div className='pb-3 flex items-center justify-between flex-wrap w-full'>
                        <input type="text" name='phone' id='phone' value={phone} placeholder='Phone number' className={`${error? 'border-[#FF0000] transition ease-in-out 100ms' : ''}transition ease-in-out duration-300 delay-100 xl:mb-0 lg:mb-0 mb-2 p-2 border-2 focus:outline-none focus:border-color rounded-[4px] xl:w-[49%] lg:w-[49%] w-full`} onChange={(e) => setPhone(e.target.value)}/>
                        <input type="text" name='' id='subject' value={subject} placeholder='Subject' className={`${error? 'border-[#FF0000] transition ease-in-out 100ms' : ''}transition ease-in-out duration-300 delay-100 p-2 border-2 focus:outline-none focus:border-color rounded-[4px] xl:w-[49%] lg:w-[49%] w-full`} onChange={(e) => setSubject(e.target.value)}/>
                    </div>
                    <textarea name="message" id="message" cols="30" rows="10" placeholder='Message' value={message} className={`${error? 'border-[#FF0000] transition ease-in-out duration-300 delay-100' : ''} p-2 border-2 focus:outline-none focus:border-color rounded-[4px] resize-none h-[100px] w-full`} onChange={(e) => setMessage(e.target.value)}></textarea>
                    {error && <p className='text-[#FF0000] font-text pt-2 text-[17px]'>{error}</p>}
                    {success && <p className='text-green-900 font-text pt-2 text-[17px]'>{success}</p>}
                </motion.form>
                <motion.button whileTap={{scale:1.1}} className='bg-bg hover:bg-color py-3 px-8 text-white border-0 rounded-[4px]' onClick={handleSubmit}>Send Message</motion.button>
            </motion.div>
            <a href="https://wa.me/2348055550436" className='capitalize py-2 px-3 fixed right-[40px] bottom-[20px] hover:bg-white hover:text-bg border-2 hover:border-bg rounded-[20px] flex items-center gap-1 text-[15px] bg-bg text-white transition ease-in-out duration-300 delay-100'><FaWhatsapp className='text-green-500 text-[20px] hover:block'/>Chat with Us</a>
        </div>
    </motion.div>

  )
}

export default Contact