import { motion } from 'framer-motion'
import React, { useEffect } from 'react'
import { slideUpVariants, zoomInVariants } from './animation'
import { TbSquareRoundedArrowDown } from "react-icons/tb";

const Services = () => {
  useEffect(() => {
    document.title = 'Services | Carewell and Investment Integrated Solutions'
  }, [])
  return (
    <div>
        <div className='xl:px-[3rem] lg:px-[3rem] px-[2rem] flex justify-between xl:my-[7rem] lg:my-[7rem] my-12 xl:flex-nowrap lg:flex-nowrap flex-wrap'>
            <motion.div variants={slideUpVariants} initial='hidden' whileInView='visible' className='flex justify-center gap-4 flex-col xl:w-[50%] lg:w-[50%] w-full'>
                <h1 className='capitalize font-main text-[30px] xl:text-[3.5rem] text-color'>your partner in<br />construction workforce solutions</h1>
                <p className='text-[15px]'>"At Carewell,  we specialize in connecting construction companies with skilled and unskilled workers who are ready to contribute to your projects. With years of industry experience, we understand the unique challenges you face in finding reliable labor. Our goal is to simplify the recruitment process, ensuring you have the right people on-site when you need them.</p>
                <a href="mailto:carewellandinvestment@gmail.com"><button className='bg-color text-white px-[10px] py-[8px] rounded-sm capitalize w-[120px]'>learn more</button></a>
            </motion.div>
            <div className=''>
                <motion.img variants={zoomInVariants} initial='hidden' whileInView='visible' src="https://images.pexels.com/photos/5668858/pexels-photo-5668858.jpeg?auto=compress&cs=tinysrgb&w=600" alt="interview" className='my-5 xl:my-0 lg:my-0 object-cover' id='home_img'/>
            </div>
        </div>
        <motion.div variants={slideUpVariants} initial='hidden' whileInView='visible' className='bg-color flex flex-col items-center gap-8 xl:p-[3rem] lg:p-[3rem] p-[2rem]'>
          <h1 className='text-[20px] capitalize font-main text-white'>our services</h1>
          <div className='xl:*:w-[24%] lg:*:w-[24%] w-full flex justify-around flex-wrap xl:flex-nowrap lg:flex-nowrap *:text-center xl:*:mb-0 lg:*:mb-0 *:mb-4'>
            <div className='flex flex-col items-center gap-3 bg-white p-4 rounded-tl-[20px] rounded-br-[20px] border-4 border-white'>
              {/* <BsFillPenFill className='text-white bg-orange-600 text-[30px] rounded-full p-2'/> */}
              <h1 className='font-main text-orange-600 capitalize text-[19px]'>skilled worker recruitment</h1>
              <p className='text-black'>We identify and recruit highly qualified professionals in various trades, from carpenters and electricians to project managers and site supervisors. Our extensive network and thorough vetting process guarantee that you’ll find the expertise you need.</p>
            </div>
            <div className='flex flex-col items-center gap-3 bg-white p-6 rounded-tl-[20px] rounded-br-[20px] shadow-xl border-4 border-white'>
              {/* <BsFillPenFill className='text-white bg-orange-600 text-[30px] rounded-full p-2'/> */}
              <h1 className='font-main text-orange-600 capitalize text-[19px]'>unskilled labor solutions</h1>
              <p className='text-black'>Whether you require general laborers for a short-term project or ongoing support, we can provide motivated workers who are eager to learn and contribute. We focus on matching the right individuals to your specific project requirements.</p>
            </div>
            <div className='flex flex-col items-center gap-3 bg-white p-6 rounded-tl-[20px] rounded-br-[20px] shadow-xl border-4 border-white'>
              {/* <BsFillPenFill className='text-white bg-orange-600 text-[30px] rounded-full p-2'/> */}
              <h1 className='font-main text-orange-600 capitalize text-[19px]'>flexible staffing options</h1>
              <p className='text-black'>We offer a range of staffing solutions tailored to your needs, including temporary placements, long-term contracts, and on-call labor. This flexibility allows you to scale your workforce efficiently.</p>
            </div>
            <div className='flex flex-col items-center gap-3 bg-white p-6 rounded-tl-[20px] rounded-br-[20px] shadow-xl border-4 border-white'>
              {/* <BsFillPenFill className='text-white bg-orange-600 text-[30px] rounded-full p-2'/> */}
              <h1 className='font-main text-orange-600 capitalize text-[19px]'>comprehensive screening process</h1>
              <p className='text-black'>Our rigorous screening ensures that all candidates meet safety standards and possess the necessary certifications. We conduct background checks and skill assessments to ensure quality and reliability.</p>
            </div>
          </div>            
        </motion.div>
        <motion.div variants={slideUpVariants} initial='hidden' whileInView='visible' className='flex justify-center items-center flex-col *:text-center xl:p-[3rem] lg:p-[3rem] p-[2rem]'>
          <h1 className='text-[20px] capitalize font-main'>why choose us?</h1>
          <div className='flex justify-center gap-6 xl:*:w-[24%] lg:*:w-[24%] w-full py-10 *:p-[1.5rem] *:bg-color flex-wrap xl:flex-nowrap lg:flex-nowrap'>
            <div className='flex items-center flex-col gap-3'>
              <h1 className='font-main capitalize text-[20px] text-cyan-400'>industry expertise</h1>
              <p className='text-white'>Our team understands the construction landscape and the importance of having the right workforce in place.</p>
            </div>
            <div className='flex items-center flex-col gap-3'>
              <h1 className='font-main capitalize text-[20px] text-cyan-400'>quick turnaround</h1>
              <p className='text-white'>We pride ourselves on our ability to deliver qualified candidates quickly, minimizing downtime on your projects.</p>
            </div>
            <div className='flex items-center flex-col gap-3'>
              <h1 className='font-main capitalize text-[20px] text-cyan-400'>personalized service</h1>
              <p className='text-white'>We take the time to understand your specific needs and work closely with you to find the best-fit candidates.</p>
            </div>
            <div className='flex items-center flex-col gap-3'>
              <h1 className='font-main capitalize text-[20px] text-cyan-400'>commitment to safety</h1>
              <p className='text-white'>Safety is our top priority. We ensure that all workers are trained and compliant with industry regulations.</p>
            </div>
            
          </div>
        </motion.div>
        <motion.div variants={slideUpVariants} initial='hidden' whileInView='visible' className='xl:px-[3rem] lg:px-[3rem] px-[2rem] flex flex-col gap-3'>
          <h1 className='text-[20px] font-main text-color capitalize'>Get started today</h1>
          <p>Ready to build your dream team? Contact us today for a consultation, and let’s discuss how we can help you meet your workforce needs.</p>
          <button className='bg-color items-center gap-2 px-2 py-2 capitalize rounded-md w-fit hover:bg-bg'><a href="mailto:carewellandinvestment@gmail.com" className='text-white text-[15px] flex items-center gap-2'>contact us now<TbSquareRoundedArrowDown className='animate-bounce text-cyan-400 text-[17px]'/></a></button>
        </motion.div>
        <div className='flex items-center justify-center py-5 xl:px-[3rem] lg:px-[3rem] px-[2rem]'>
          <h1>Transform your construction projects with the right talent—partner with Carewell for all your workforce solutions!</h1>
        </div>
    </div>
  )
}

export default Services