import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import logo from '../assets/images/Darobet logo.png'
import { GiHamburgerMenu } from "react-icons/gi";
import { AnimatePresence, motion, MotionConfig } from 'framer-motion';
import { FaFacebook, FaSquareXTwitter } from "react-icons/fa6";
import { IoCloseSharp } from "react-icons/io5";
import { FaInstagram } from 'react-icons/fa';

const Nav_Link = [
  {
      path: '/ourservices',
      display: 'our service'
  },
  {
      path: '/about',
      display: 'about us'
  },
  {
    path: '/ourprojects',
    display: 'projects'
  },
  {
    path: '/team',
    display: 'the team'
},
{
  path: '/contact',
  display: 'contact us'
},

]


const DarobetHeader = () => {
  const [activeNav, setActiveNav] = useState('')
  const [show, setShow] = useState(false)
  const [mobileNav, setMobileNav] = useState(false);

	const toggleMobileNav = () => {
		setMobileNav(!mobileNav);
	};

 const toggleActive = () => {
  setActiveNav(!activeNav)
  setMobileNav(false)
 }
  const transitionNav = () => {
    if(window.scrollY > 80) {
      setShow(true)
    }else{
      setShow(false)
    }
   }

   useEffect(() => {
    window.addEventListener('scroll', transitionNav)
    return () => window.removeEventListener('scroll',transitionNav)
   },[])

  return (
       <div className={`flex items-center justify-between px-[1rem] xl:px-[3rem] lg:px-[3rem] bg-transparent fixed left-0 right-0 ${show && 'fixed left-0 right-0 bg-white transition transform ease-out delay-150 duration-200 shadow-lg z-[99999]'}`}>
          <div>
            <Link to='/' className='flex items-center'>
              <img src={logo} alt="darobet nigeria limited" className='xl:w-[70px] lg:w-[70px] w-[60px] md:w-[70px]'/>
              <div className='flex flex-col max-w-[155px] uppercase text-[14px] font-semibold text-bg leading-4 font-text'>
                <h1 className='w-full xl:tracking-[8px] lg:tracking-[8px] tracking-[9px] md:tracking-[8px] xl:text-[17px] lg:text-[17px] text-[15px] font-semibold'>darobet</h1>
                <span className='tracking-[1px] font-semibold'>nigeria limited</span>
              </div>
            </Link>
          </div>
          <div>
          <ul className='hidden items-center xl:flex lg:flex md:flex gap-5'>
            {Nav_Link.map((item, index) => (
              <li className='hover:bg-color text-bg hover:text-white py-[5px] px-[8px] hover:rounded-[5px] text-[16px] capitalize font-medium font-text transition ease-in-out duration-300 delay-0' key={index}>
                <NavLink to={item.path} className={`activeNav === '#' ? 'active' : '' active:bg-color transition ease-out duration-300 delay-0`} onClick={() => setActiveNav(!activeNav)}>{item.display}</NavLink>
              </li>
            ))}
          </ul>

          {/* //Mobile Nav */}
          <motion.button
          className='block items-center xl:hidden lg:hidden md:hidden'
          initial="hide"
					animate={mobileNav ? "show" : "hide"}
					onClick={toggleMobileNav}
          >
            {mobileNav ? <IoCloseSharp className='text-bg text-[25px] hover:text-[28px] hover:bg-color hover:text-white hover:rounded-full hover:p-[2px]'/> :  <GiHamburgerMenu className='text-bg text-[23px] hover:text-[30px] hover:bg-color hover:text-white hover:rounded-full hover:p-[5px]'/>}</motion.button>
          <AnimatePresence>
					{mobileNav && (
						<MotionConfig
							transition={{
								type: "spring",
								bounce: 0.1,
							}}
						>
          <motion.div
          key="mobile-nav"
          variants={{
            hide: {
              x: "-100%",
              transition: {
                type: "spring",
                bounce: 0.1,
                when: "afterChildren",
                staggerChildren: 0.25,
              },
            },
            show: {
              x: "0%",
              transition: {
                type: "spring",
                bounce: 0.1,
                when: "beforeChildren",
                staggerChildren: 0.25,
              },
            },
          }}
          initial="hide"
          animate="show"
          exit="hide"
          className='fixed left-0 right-0 top-[57px] bg-color z-[999999999] h-[100vh] flex items-center flex-col gap-[90px] xl:hidden lg:hidden md:hidden'
          >
            <motion.ul
            variants={{
              hide: {
                y: "25%",
                opacity: 0,
              },
              show: {
                y: "0%",
                opacity: 1,
              },
            }}
            className='items-center flex flex-col py-[40px] gap-[25px]'>
              {Nav_Link.map((item, index) => (
                <li className='hover:text-black text-white px-[5px] py-[3px] flex-col rounded-sm text-[16px] capitalize font-medium font-text transition ease-in-out duration-300' key={index}>
                  <NavLink to={item.path} className={activeNav === '#' ? 'active' : ''} onClick={toggleActive}>{item.display}</NavLink>
                </li>
              ))}
            </motion.ul>
            <motion.div
									variants={{
										hide: {
											y: "25%",
											opacity: 0,
										},
										show: {
											y: "0%",
											opacity: 1,
										},
									}}
									className="w-[90%] h-px bg-white/30"
								></motion.div>
                <motion.ul
									variants={{
										hide: {
											y: "25%",
											opacity: 0,
										},
										show: {
											y: "0%",
											opacity: 1,
										},
									}}
									className="list-none flex justify-center items-center gap-x-4"
								>
									<li>
                    <a href=""><FaFacebook className='text-white text-[20px]'/></a>
									</li>
									<li>
                  <a href="https://www.instagram.com/darobetinternational?igsh=MTc5ODBuYXlvbTUxcw%3D%3D&utm_source=qr"><FaInstagram className='text-white text-[20px]'/></a>
									</li>
									<li>
                    <a href=""><FaSquareXTwitter className='text-white text-[20px]'/></a>
									</li>
								</motion.ul>
          </motion.div>
          </MotionConfig>
          )}
        </AnimatePresence>
        </div>
    </div>
  )
}

export default DarobetHeader