import React from 'react'
import logo from '../assets/images/logo.png'
import { FaFacebook } from "react-icons/fa6";
import { AiFillTwitterCircle } from "react-icons/ai";

const Footer = () => {
  return (
    <div className='px-[1rem] xl:px-[2rem] lg:px-[2rem] gap-2 flex items-center justify-between py-3 border-t-2 flex-wrap xl:flex-nowrap lg:flex-nowrap'>
        <div>
            <div className='flex items-center'>
              <img src={logo} alt="carewell and investment solution" className='xl:w-[80px] lg:w-[80px] md:w-[80px] w-[55px]'/>
              <div className='flex flex-col font-semibold text-bg *xl:text-[14px] *:lg:text-[14px] *:text-[10px] leading-tight *:font-head'>
                <h1 className=''>carewell and investment</h1>
                <span className=''>integrated solutions</span>
                <span className=''>limited</span>
              </div>
            </div>
          </div>
          <div>
            <p className='text-[14px]'>&copy; 2024 All rights reserved. <span className='text-color capitalize'>carewell and investment integrated solutions</span></p>
          </div>
          <div className='flex items-center gap-2'>
            <FaFacebook/>
            <AiFillTwitterCircle/>

          </div>
    </div>
  )
}

export default Footer