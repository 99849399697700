import { motion } from 'framer-motion'
import React, { useEffect } from 'react'
import { slideUpVariants, zoomInVariants } from '../assets/animation'
import qualityImage from '../assets/images/DAROBET WEBSITE.pdf-image-002.jpg'
import qualityImage2 from '../assets/images/DAROBET WEBSITE.pdf-image-001.jpg'
import { IoShieldCheckmark } from "react-icons/io5";
import qualityImg from '../assets/images/image.png'


const About = () => {
  useEffect (() => {
    document.title = 'About Us | Darobet Nigeria Limited'
  },[])
  return (
    <div className=''>
      <motion.div className='flex justify-center flex-col xl:px-[3rem] lg:px-[3rem] px-[2rem] py-[2rem] xl:h-[70vh] lg:h-[60vh] h-[60vh]' id='about_bg'>
            <motion.h1 variants={zoomInVariants} initial='hidden' whileInView='visible' className='xl:text-5xl lg:text-4xl text-[1.5rem] capitalize xl:pb-4 lg:pb-3 pb-2 text-color font-semibold font-heading'>About Us</motion.h1>
            <p className='xl:text-[17px] lg:text-[17px] text-[12px] xl:w-[60%] lg:w-[60%] md:w-[60%] w-full text-white'>Darobet Nigeria Limited (DNL) is a civil engineering and construction company registered to carry out the construction of roads and buildings throughout the federation of Nigeria.
            The company was incorporated on September 1989 and has since been involved in the execution of various construction projects in Nigeria.</p>
        </motion.div>
        <div className='flex items-center w-full flex-col'>
          
          <motion.h1 variants={zoomInVariants} initial='hidden' whileInView='visible' className='xl:text-[20px] lg:text-[20px] text-[17px] xl:py-[4rem] lg:py-[4rem] py-[2rem] xl:leading-[30px] leading-[24px] lg:leading-[30px] xl:px-[3rem] lg:px-[3rem] px-[2rem] xl:text-center lg:text-center text-left xl:w-[70%] lg:w-[70%] w-full'>Darobet Nigeria Limited has earned a solid reputation for exceeding our client’s expectations in safety, budget, schedule and quality. Over the past 23 years, we have evolved from a small excavating and concrete contractor into a full-service provider based in the federal Capital Territory Abuja Nigeria. We expanded our focus to serve client beyond the Abuja area to across the south west region in Nigeria and also to outside Nigeria border to other African countries with our office registered in the FCT Abuja and Canada.</motion.h1>
          <div className='xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap flex items-center xl:px-[3rem] lg:px-[3rem] md:px-[3rem] px-[2rem] justify-between bg-bg gap-4'>
            <motion.h1 variants={slideUpVariants} initial='hidden' whileInView='visible' className='xl:text-[20px] lg:text-[20px] text-[17px] leading-[30px] xl:w-[45%] lg:w-[45%] w-full text-cyan-300 py-[2rem]'><small className='xl:text-[3rem] text-[1.5rem]'>"</small>Quality without compromise is our commitment one based on a solid work ethic as demonstrated by our experienced personnel. Our staffs consist of degreed and registered professional in the areas of estimating, project management, field operations and accounting.</motion.h1>
            <div className='xl:h-[60vh] lg:h-[60vh] h-[40vh] xl:w-[55%] lg:w-[55%] w-full flex items-center'>
            <motion.div variants={zoomInVariants} initial='hidden' whileInView='visible' className='xl:h-[60vh] lg:h-[60vh] h-[40vh] w-full flex items-center'>
              <img src={qualityImage} alt="darobet on site" className='object-cover object-center w-[50%] xl:h-[50vh] lg:h-[50vh] h-[35vh] rounded-bl-xl rounded-tl-xl border-white border-2'/>
              <img src={qualityImage2} alt="darobet on site" className='object-cover object-center w-[50%] xl:h-[50vh] lg:h-[50vh] h-[35vh] rounded-br-xl rounded-tr-xl border-2 border-white'/>
            </motion.div>
            <h1 className='text-2xl font-Text mx-auto absolute flex items-center justify-center p-8 bg-bg font-semibold text-white w-[130px] text-center rounded-full left-0 right-0 xl:left-[41rem]'>since 1989</h1>
            </div>
          </div>
          <div className='xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap flex items-center justify-between gap-2 w-full py-[2rem]'>
            <motion.h1 variants={slideUpVariants} initial='hidden' whileInView='visible' className='p-[2rem] xl:p-[3rem] lg:p-[3rem] xl:text-[20px] lg:text-[20px] text-[17px] xl:leading-[30px] leading-[24px] lg:leading-[30px] xl:w-[50%] lg:w-[50%] w-full text-bg'>Darobet’s management team and field work force brings a depth of experience in the construction industry, with a keen focus for developing and implementing innovative solutions. Based on our combined experience and continual employee development and training, we foster a long-term client relationship that defined the way we think, organized and perform the services leading to our continued success.</motion.h1>
            <motion.img variants={slideUpVariants} initial='hidden' whileInView='visible' src={qualityImg} alt="" className='xl:w-[50%] lg:w-[50%] w-full'/>
          </div>
        </div>
        <div className='py-16 px-[2rem] flex justify-center gap-8 xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap'>
          <motion.div variants={zoomInVariants} initial='hidden' whileInView='visible' className='xl:w-[35%] lg:w-[35%] w-full leading-[1.7rem] bg-[#022E45] p-5 rounded-[10px] flex flex-col gap-2'>
            <h1 className='text-[20px] text-cyan-400'>Our Mission</h1>
            <ul className='flex flex-col gap-3 text-white'>
              <li className='flex items-center gap-2 border-l-[3px] pl-3 border-cyan-400'>We are to provide qualitative housing at affordable cost to the generality of people.</li>
              <li className='flex items-center gap-2 border-l-[3px] pl-3 border-cyan-400'>To carry out the business of construction and engineering service in a faster but efficient manner, cheaper yet meeting all client requirements and better meeting all quality standards quality.</li>
            </ul>
          </motion.div>
          <motion.div variants={zoomInVariants} initial='hidden' whileInView='visible' className='xl:w-[35%] lg:w-[35%] w-full leading-[1.7rem] bg-[#022E45] p-5 rounded-[10px] flex gap-2 flex-col'>
            <h1 className='text-[20px] text-cyan-400'>Our Offer</h1>
            <p className='text-white'>We offer public and private experience plus excellent professionalism and quality services. Our competitive edge is attributed to:</p>
            <ul className='flex flex-col gap-3 text-white'>
              <li className='flex items-center gap-2'><IoShieldCheckmark className='text-cyan-400 text-[14px]'/>High Skills</li>
              <li className='flex items-center gap-2'><IoShieldCheckmark className='text-cyan-400 text-[14px]'/>Affordable Price</li>
              <li className='flex items-center gap-2'><IoShieldCheckmark className='text-cyan-400 text-[14px]'/>Home Grown Expertise</li>
            </ul>
          </motion.div>
        </div>
    </div>
  )
}

export default About