import React, { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import DarobetHeader from './DarobetHeader'
import DarobetFooter from './DarobetFooter'
import Header from '../carewell component/Header'
import Footer from '../carewell component/Footer'


const Layout = () => {
  const {pathname} = useLocation()
  const [showDaroNav, setShowDaroNav] = useState(null)
  const [showCareNav, setShowCareNav] = useState(null)

  useEffect(() => {
    if(pathname === '/'){
      setShowDaroNav(true)
    } else if(pathname === '/carewell_investment'){
      setShowCareNav(true)
      setShowDaroNav(false)
    }
    
},[pathname])


  return (   
      <div>
        {showDaroNav ?
        <>
        <DarobetHeader/>
        <Outlet/>
        {pathname !== '/contact' ? <DarobetFooter/> : ''}
        </> :
        <>
        <Header/>
        <Outlet/>
        <Footer/>
        </>
      
      }
    </div>
  )
}

export default Layout