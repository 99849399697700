import { motion } from 'framer-motion'
import React, { useEffect } from 'react'
import { slideUpVariants, zoomInVariants } from '../assets/animation'
import { FaLongArrowAltRight } from "react-icons/fa";
import { SwiperNavButtons } from '../component/SwiperNavButton';
import { A11y, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import AnimatedCounter from '../component/AnimatedCounter';
import Card from '../component/Card';
import Fab from '../assets/images/pro3.jpg.png'
import Build from '../assets/images/pro2.jpg.png'
import { useNavigate } from 'react-router-dom';


const OurServices = () => {
  useEffect(() => {
    document.title = 'Our Service | Darobet Nigeria Limited'
  },[])
  const navigate = useNavigate() 
  const handleNav = () => {
    navigate('/contact')
  }
  return (
    <div className='flex justify-center flex-col'>
      <div className='xl:px-[3rem] lg:px-[3rem] px-[2rem] py-[10rem] flex-col flex text-left items-center justify-center xl:h-[70vh] lg:h-[60vh] h-[50vh]' id='service_bg'>
        {/* <h5 className='uppercase border-l-4 border-bg pl-2'>what we offer</h5> */}
        <motion.h1 variants={slideUpVariants} initial='hidden' whileInView='visible' className='xl:text-[3.5rem] lg:text-[3.5rem] text-[2.5rem] font-heading capitalize text-white text-center'>our services</motion.h1>
      </div>
      <motion.div  variants={slideUpVariants} initial='hidden' whileInView='visible' className='flex justify-between gap-2 p-5 xl:p-10 lg:p-10  mx-auto w-[90%] relative -top-[95px] bg-white xl:flex-nowrap lg:flex-nowrap flex-wrap' id='service'>
        <div className='xl:w-[20%] flex gap-2 flex-col'>
          <p className='uppercase border-l-4 border-bg text-color pl-2'>our services</p>
          <h1 className='capitalize xl:text-[30px] lg:text-[30px] text-[25px] text-bg'>Take Business services from our experienced stuff</h1>
        </div>
        <div className='xl:w-[70%]'>
          <p>DNL construction activity focuses basically on the development of all types of Civil Works and Building infrastructures through construction projects for both public and private clients. We carry out projects under concession arrangements through various public-private partnership contract models, covering the whole concession business value chain, from project design to financing, construction, commissioning and operation. Improving society, generating wealth to guarantee the well-being of the citizens it serves, in the final analysis, is a primordial part of DNL mission.</p>
        </div>

      </motion.div>
      <div variants={slideUpVariants} initial='hidden' whileInView='visible' className='flex items-center justify-between mx-auto pb-8 xl:my-[4rem] lg:my-[4rem] md:my-[4rem] xl:w-[70%] lg:w-[70%] w-full xl:flex-row lg:flex-row md:flex-row flex-col gap-[2rem] xl:gap-0 lg:gap-0 md:gap-0'>
        <div className='flex flex-col items-center'>
          <h1 className='xl:text-[70px] lg:text-[70px] text-[50px] text-bg flex items-center font-semibold'><AnimatedCounter from={0} to={200}/><small className='text-[20px]'>+</small></h1>
          <p className='text-[17px] capitalize text-center text-bg'>successful project</p>
        </div>
        <div className='flex flex-col items-center'>
          <h1 className='xl:text-[70px] lg:text-[70px] text-[50px] text-bg flex items-center font-semibold'><AnimatedCounter from={0} to={20}/><small className='text-[20px]'>+</small></h1>
          <p className='text-[17px] capitalize text-center text-bg'>years of experience</p>
        </div>
        <div className='flex flex-col items-center'>
          <h1 className='xl:text-[70px] lg:text-[70px] text-[50px] text-bg flex items-center font-semibold'><AnimatedCounter from={0} to={50}/><small className='text-[20px]'>+</small></h1>
          <p className='text-[17px] capitalize text-center text-bg'>happy clients and counting...</p>
        </div>
      </div>
      <Card/>
      <div className='flex justify-between w-full xl:px-[3rem] lg:px-[3rem] px-[2rem] gap-2 xl:flex-nowrap lg:flex-nowrap flex-wrap xl:h-[70vh] my-14'>
      <motion.div variants={slideUpVariants} initial='hidden' whileInView='visible' className='flex justify-center flex-wrap gap-1 xl:w-[50%] md:w-full h-full'>
        <div className='xl:w-[45%] lg:w-[45%] md:w-[49%] flex flex-col gap-1 scale-100 transition ease-in-out duration-300 h-[50%]'>
          <img src={Build} alt="construction service" className='relative h-full object-cover object-center'/>
          <h1 className='text-white bg-gray-800 capitalize font-semibold p-[6px] absolute bottom-0 text-center text-[15px]'>Construction service incl. infrastructure repairs</h1>
        </div>
        <div className='xl:w-[45%] lg:w-[45%] md:w-[49%] flex flex-col gap-1 scale-100 transition ease-in-out duration-300 h-[50%]'>
          <img src={Fab} alt="fabrication" className='relative h-full object-cover object-center'/>
          <h1 className='text-white bg-gray-800 capitalize font-semibold absolute p-[6px] bottom-0 text-center text-[15px]'>Fabrication of aluminum roofing sheet</h1>
        </div>
        <div className='xl:w-[45%] lg:w-[45%] md:w-[49%] flex flex-col gap-1 scale-100 transition ease-in-out duration-300 h-[50%]'>
          <img src='https://images.pexels.com/photos/26953860/pexels-photo-26953860/free-photo-of-repair-work-sign.jpeg?auto=compress&cs=tinysrgb&w=600' alt="Engineering service including road maintenance" className='relative h-full object-cover object-center rounded-bl-[12px]'/>
          <h1 className='text-white bg-gray-800 capitalize absolute font-semibold p-[6px] bottom-0 overflow-hidden text-[15px] text-center'>Engineering service including road maintenance</h1>
        </div>
        <div className='xl:w-[45%] lg:w-[45%] md:w-[49%] flex flex-col gap-1 scale-100 transition ease-in-out duration-300 h-[50%]'>
          <img src='https://images.pexels.com/photos/5915240/pexels-photo-5915240.jpeg?auto=compress&cs=tinysrgb&w=600' alt="project management" className='relative h-full object-cover object-center'/>
          <h1 className='text-white bg-gray-800 capitalize font-semibold p-[6px] absolute bottom-0 text-center text-[15px]'>project management</h1>
        </div>
      </motion.div>
      <div className='xl:w-[50%] md:w-full flex flex-col gap-4 justify-evenly xl:mt-0 lg:mt-0 mt-6 '>
        <p className='px-[5px] border-l-[4px] border-color'>what we offer</p>
        <motion.h1 variants={zoomInVariants} initial='hidden' whileInView='visible' className='xl:text-[3.4rem] lg:text-[3.4rem] text-[2.5rem] font-heading'>Providing solutions for construction, management and restoration.</motion.h1>
        <motion.p variants={zoomInVariants} initial='hidden' whileInView='visible' className='text-[15px]'>DNL construction activity focuses basically on the development of all types of Civil Works and Building infrastructures through construction projects for both public and private clients. We carry out projects under concession arrangements through various public-private partnership contract models, covering the whole concession business value chain, from project design to financing, construction, commissioning and operation. Improving society, generating wealth to guarantee the well-being of the citizens it serves, in the final analysis, is a primordial part of DNL mission.</motion.p>
        <a href="./contact" className='xl:w-[140px] lg:w-[140px] w-[100px] text-[14px] text-center px-[5px] py-3 border-2 border-color hover:bg-color hover:text-white'><button >Learn More</button></a>
      </div>
      </div>
      <div className='flex justify-center gap-10 px-[2rem] xl:px-[3rem] lg:px-[3rem] py-[5rem] xl:flex-nowrap lg:flex-nowrap flex-wrap '>
        <motion.div  variants={slideUpVariants} initial='hidden' whileInView='visible' className='xl:w-[30%] lg:w-[40%] md:w-[50%] w-full flex flex-col gap-2 bg-bg p-5 rounded-lg'>
            <h1 className='font-semibold text-cyan-400'>History</h1>
            <p className='text-white'>Darobet started out as a construction company 2002 and over the years have developed the road construction and infrastructural repairs, project management and construction of Aluminum Roofing sheets. <span className='text-cyan-200'>This is as a request from clients due to the exceptional feedbacks in our offerings and result in our projects.</span></p>
          </motion.div>
        <motion.div  variants={slideUpVariants} initial='hidden' whileInView='visible' className='xl:w-[30%] lg:w-[40%] md:w-[50%] w-full'>
          <h1 className='font-semibold pb-2'>DNL’s commitment to society is summarized in three fields of action:</h1>
          <ul className='flex flex-col gap-2 list-disc'>
            <li>Respect for the social, economic, and environmental setting.</li>
            <li>Promotion of innovation and research in its application to infrastructure development.</li>
            <li>Creation of employment and promoting the well-being, as an economic motor for its stakeholders.</li>
          </ul>
          </motion.div>
          <motion.div  variants={slideUpVariants} initial='hidden' whileInView='visible' className='flex flex-col gap-2 xl:w-[30%] lg:w-[40%] md:w-[50%] w-full'>
            <h1 className='font-semibold'>This performance and all activities are impregnated with the corporate value that DNL has developed over 20 years of history and form the basis of the actions of all the employees:</h1>
            <ul className='flex flex-col gap-2 list-disc'>
              <li>Achieving profitability.</li>
              <li>Respecting integrity.</li>
              <li>Commitment to their work.</li>
              <li>Seeking the client’s trust.</li>
              <li>Excellence in their professional activities</li>
            </ul>
          </motion.div>
          
         
      </div>
      <div className='px-[2rem] lg:px-[3rem] xl:px-[3rem]'>
          <h1 className='uppercase text-[17px] flex items-center gap-2 font-semibold text-bg'>darobet's gallery</h1>
          <motion.div variants={zoomInVariants} initial='hidden' whileInView='visible' id='app'>
          <h1>
        <Swiper
          modules={[Navigation, Pagination, A11y]}
          spaceBetween={30}
          slidesPerView="auto"
        >
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperSlide className="res-slide" />
          <SwiperNavButtons />
        </Swiper>
      </h1>
      </motion.div>
        </div>
        <div className='flex items-center justify-center flex-col py-8 bg-bg text-white gap-6' id='build'>
          <h1 className='font-heading text-[1.3rem]'>Let's build the future. Together</h1>
          <button className='bg-white text-bg px-3 py-2 rounded-[4px] font-semibold hover:text-white hover:bg-color' onClick={handleNav}><a>Contact Us</a></button>
        </div>
    </div>
  )
}

export default OurServices