import React from 'react'
import { NavLink } from 'react-router-dom'
import logo from '../assets/images/Darobet logo.png'
import { MdLocalPhone, MdEmail } from "react-icons/md";
import { ImLocation2 } from "react-icons/im";
import { FaFacebook, FaInstagram, FaSquareXTwitter } from "react-icons/fa6";
import { slideUpVariants } from '../assets/animation';
import { motion } from 'framer-motion';


const Nav_Link = [
  {
      display: 'our service'
  },
  {

      display: 'about us'
  },
  {

    display: 'projects'
  },
  {

    display: 'our people'
},
{

  display: 'contact us'
},

]

const DarobetFooter = () => {
  return (
    <motion.div  className='flex items-center flex-col py-6 bg-[#DAE6F5] gap-3' >
      <div className={`flex justify-between px-[1rem] xl:px-[3rem] lg:px-[3rem] py-2 xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap gap-8 border-b-2 border-color w-full }`}>
          <div className='flex flex-col gap-2 w-full'>
            <div className='flex flex-row items-center'>
              <img src={logo} alt="darobet nigeria limited" className='w-[70px]'/>
              <div className='flex flex-col max-w-[155px] uppercase text-[14px] font-semibold text-color leading-4 font-text'>
                <h1 className='w-full tracking-[8px] text-[17px]'>darobet</h1>
                <span className='tracking-[1px]'>nigeria limited</span>
              </div>
            </div>
              {/* <motion.h1 variants={slideUpVariants} initial='hidden' whileInView='visible' className='text-color font-text text-[15px]'>Darobet Nigeria Limited has earned a solid reputation for exceeding our client’s expectations in safety, budget, schedule and quality. Over the past 23 years, we have evolved from a small excavating and concrete contractor into a full-service provider based in the federal Capital Territory Abuja Nigeria.</motion.h1> */}
          </div>
          <div variants={slideUpVariants} initial='hidden' whileInView='visible' className='flex flex-col w-full'>
            <ul className='flex flex-col gap-3'>
              <h1 className='text-[15px] font-semibold text-cyan-400 uppercase font-text'>Quick Links</h1>
              {Nav_Link.map((item, index) => (
                <li className='px-[5px] py-[2px] rounded-sm text-[15px] capitalize font-medium text-color' key={index}>
                  {item.display}
                </li>
              ))}
            </ul>
            <div className='flex gap-4 pt-2 '>
              <a href="#"><FaFacebook className='text-color'/></a>
              <a href="https://www.instagram.com/darobetinternational?igsh=MTc5ODBuYXlvbTUxcw%3D%3D&utm_source=qr"><FaInstagram className='text-color'/></a>
              <a href="#"><FaSquareXTwitter className='text-color'/></a>
              
            </div>
          </div>
          <div variants={slideUpVariants} initial='hidden' whileInView='visible' className='flex-col'>
            <h1 className='text-cyan-400 uppercase font-semibold font-text'>Get in touch</h1>
            <div className='flex gap-1 flex-col '>
                <div className='flex items-center gap-3 p-1'>
                    <div className='p-1 rounded-full bg-bg'>
                        <MdLocalPhone className='text-[12px] text-white'/>
                    </div>
                    <div>
                        <h1 className='text-bg font-semibold text-[14px]'>Phone Number</h1>
                        <p className='text-[15px] text-color'>+234-803-3356733</p>
                        <p className='text-[15px] text-color'>+234-805-5550436</p>

                    </div>
                </div>
                <div className='flex items-center gap-3 p-1' id='contact_bg'>
                    <div className='p-1 rounded-full bg-bg'>
                        <MdEmail className='text-[12px] text-white'/>
                    </div>
                    <div>
                        <h1 className='text-bg font-semibold text-[14px]'>Email address</h1>
                        <p className='hover:text-bg underline text-[15px] text-color'><a href="mailto:aosuporu@yahoo.com">aosuporu@yahoo.com</a></p>
                        <p className='hover:text-bg underline text-[15px] text-color'><a href="mailto:darobetinternational@gmail.com">darobetinternational@gmail.com</a></p>
                    </div>
                </div>

                <div className='flex items-center gap-3 p-1' id='contact_bg'>
                    <div className='p-1 rounded-full bg-bg'>
                        <ImLocation2 className='text-[12px] text-white'/>
                    </div>
                    <div>
                        <h1 className='text-bg font-semibold text-[14px]'>Location</h1>
                        <p className='text-[15px] text-color'>Plot 325 Kingsley Ebenyi Close, Apo, Gudu District Abuja. P.O.Box 3978, Garki Abuja.</p>
                        <p className='text-[15px] text-color'>Canada.</p>
                    </div>
                </div>
                
            </div>
          </div>
    </div>
    <h1 className='py-2 text-black px-[2rem] xl:px-0 lg:px-0 md:px-0 text-center text-[15px]'>All right reserved &copy; 2024. Darobet Nigeria Limited</h1>
    </motion.div>

  )
}

export default DarobetFooter