import React, { useEffect } from 'react'
import { zoomInVariants } from '../assets/animation';
import { motion } from 'framer-motion';
import ProjectLists from '../component/ProjectLists';


const OurProjects = () => {
  useEffect (() => {
    document.title = 'Featured Projects | Darobet Nigeria Limited'
  },[])
  return (
    <div className='w-full'>
      <div className='h-[50vh] flex flex-col justify-center px-[2rem] xl:px-[3rem] lg:px-[3rem]' id='project1_bg'>
        <motion.h1 variants={zoomInVariants} initial='hidden' whileInView='visible' className='font-heading text-center xl:text-[4.5rem] lg:text-[4.5rem] text-[2.5rem] tracking-[10px] capitalize text-white'>projects.</motion.h1>
        {/* <p className='text-white'>CAREWELL AND INVESTMENT INTEGRATED SOLUTIONS LIMITED is a marketing arm of Darobet Nigeria Limited. It was set up for marketing purpose for Solar Panels, Inverter, Darobet Scrap and Used items and Darobet Estates and Property.</p> */}
      </div>
      <div className='flex flex-col px-[2rem] xl:px-[3rem] lg:px-[3rem] py-6 xl:flex-nowrap lg:flex-nowrap flex-wrap md:flex-nowrap gap-1 bg-gray-100'>
        <h1 className='font-semibold text-[20px]'>Featured Properties</h1>
        <p>Available listings you may like:</p>
        {/* <div>
          <span className='h-[40px] xl:w-[400px] lg:w-[400px] w-full flex items-center justify-between border-2 border-bg pl-2 rounded-[4px]'>
          <input type="text" placeholder='search name...'  className='focus:outline-none w-full text-[14px] bg-transparent'/>
          <IoSearchSharp className='bg-color text-white h-full px-[10px] text-[40px] hover:bg-opacity-90 cursor-pointer'/>
          </span>
        </div> */}
      </div>
      <ProjectLists/>
    </div>
  )
}

export default OurProjects