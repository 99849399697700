import { motion } from 'framer-motion'
import React, { useState } from 'react'
import logo from '../assets/images/army.png'
import logo1 from '../assets/images/health.png'
import logo2 from '../assets/images/images.png'
import logo3 from '../assets/images/navdac.jpeg'
import logo4 from '../assets/images/NCC.jpeg'
import logo5 from '../assets/images/USAID.png'
import logo6 from '../assets/images/works.png'
import logo7 from '../assets/images/totalenergies_logo_rgb.png'
import logo8 from '../assets/images/ncdc.png'
import logo9 from '../assets/images/bedc.png'
import { zoomInVariants } from '../assets/animation'

const clientList = [
            {id: 1, image: logo},
            {id: 2, image: logo1},
            {id: 3, image: logo2},
            {id: 4, image: logo3},
            {id: 5, image: logo4},
            {id: 6, image: logo5},
            {id: 7, image: logo6},
            {id: 8, image: logo7},
            {id: 9, image: logo8},
            {id: 10, image: logo9}


]

const Card = () => {
    

  return (
    <motion.div className='flex items-center justify-center flex-col py-16 mx-3'>
      <h1 className='text-[18px] font-semibold'>Our Clients</h1>
          <div className='flex items-center gap-8 xl:justify-evenly lg:justify-evenly justify-start py-12 w-full mx-8 xl:mx-0 lg:mx-0  overflow-scroll scrollbar-hide'>
            {clientList.map(({image, id}) => {
              return (
                <motion.div variants={zoomInVariants} initial='hidden' whileInView='visible' transition={{duration:1.0, delay: id * 0.5}} className=''  key={id}>
                  <img src={image} alt="" className='max-w-[80px] w-[80px] xl:max-w-[120px] xl:w-[120px]'/>
                </motion.div>
              )
            })}
          </div>
  </motion.div>
  )
}

export default Card