import { motion } from 'framer-motion'
import React from 'react'
import { slideUpVariants } from '../assets/animation'
import ProjectsList from '../carewell component/ProjectsList'
import { Link } from 'react-router-dom'


const ProjectLists = () => {
  return (
    <div className='flex flex-wrap justify-center w-full gap-5 px-5 bg-gray-100 pb-12'>
        {ProjectsList.map(({name,price,media, status, index}) => {
            return (
                
                <motion.div variants={slideUpVariants} initial='hidden' whileInView='visible' transition={{duration:0.5, delay: index * 0.5}} className='mb-2 p-2 xl:w-[23%] lg:w-[28%] md:w-[38%] w-full' key={index}>
                    <img src={media[0]} alt="" className='w-full hover:scale-105 object-cover object-center transition ease-in-out duration-500 delay-150 mb-2 rounded-[5px] h-[300px] border-[6px] border-white shadow'/>
                    <div className='flex justify-between  gap-1 transition ease-in-out duration-500 delay-150'>
                        <h1 className='capitalize text-bg font-semibold text-[15px] flex gap-2'>{name}</h1>
                        {status && <h1>{status === 'completed' ? <span className='text-white bg-green-800 px-[5px] uppercase w-fit text-center text-[10px] rounded-[20px] font-semibold py-[2px]'>completed</span> : <span className='text-white bg-orange-600 lowercase text-center text-[12px] rounded-[20px] px-[5px] font-normal py-[0.5px]'>ongoing</span>}</h1>}
                        {/* <p className='text-gray-900 first-letter:uppercase text-[15px]'>{details}</p> */}
                        {/* <span className='text-bg font-semibold text-[14px]'>{price}</span> */}
                        
                    </div>
                    <Link to={`/carewell_investment`}><button className='px-[8px] py-[4px] bg-color text-white mt-2 hover:border-bg hover:bg-white hover:text-bg border-white border-2 rounded-[5px] text-[14px]'>Details</button></Link>
                </motion.div>
                
            )
        })}
    </div>
  )
}
export default ProjectLists