import React, { useEffect } from 'react'
import scrap from '../assets/images/forming machine.jpg'
import { slideUpVariants, zoomInVariants } from './animation';
import { motion } from 'framer-motion';
import { FcCustomerSupport } from "react-icons/fc";
import { FaToolbox } from "react-icons/fa";
import { AiFillProduct } from "react-icons/ai";
import logo from '../assets/images/logo.png'


const about = [
  {
  heading: 'solar panels',
  details: 'Utilize the energy of green sources with our superior solar panels. Our solar systems, which are built for durability and efficiency, save energy bills and support environmental sustainability.',
  img: 'https://images.pexels.com/photos/8853502/pexels-photo-8853502.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    heading: 'inverters',
    details: 'Invest in a dependable power source with our cutting-edge inverters. Our inverters improve your energy independence by offering smooth energy control for both homes and businesses.',
    img: 'https://www.simba.com.ng/wp-content/uploads/2020/04/ILTT-EX-18W48NZelioTrolley-1024x1024.png'
  },
  {
    heading: 'Darobet Scraps and Used Items',
    details: 'View our selection of used and scrap Darobet goods, which provide useful materials for recycling and reuse. Make a positive impact on the circular economy by locating affordable project solutions.',
    img: scrap
  },
  {
    heading: 'Darobet Estates and Property',
    details: 'Invest in your future with our prime real estate offerings. We provide a variety of properties that cater to both residential and commercial needs, ensuring you find the perfect space to call your own.',
    img: 'https://images.pexels.com/photos/13203194/pexels-photo-13203194.jpeg?auto=compress&cs=tinysrgb&w=600'
  }
]

const choose = [
    {
      icon: <FaToolbox/>,
      heading: 'expertise',
      details: ' Leveraging the experience and reputation of Darobet Nigeria Limited, we offer trusted solutions across various sectors.'
    },
    {
      icon: <AiFillProduct/>,
      heading: 'quality products',
      details: 'We prioritize quality and sustainability in all our offerings, ensuring you receive the best value.'
    },
    {
      icon: <FcCustomerSupport/>,
      heading: 'customer focus',
      details: 'Our dedicated team is here to understand your needs and provide personalized service.'
    }
]
const Carewell_Investment = () => {

  useEffect(() => {
    document.title = `Home | Carewell and Investment Integrated Solutions`
  }, [])
  
  return (
    <div className='w-full'>
      <div className='xl:h-[80vh] lg:h-[60vh] h-[50vh] flex flex-col justify-center px-[2rem] xl:px-[3rem] lg:px-[3rem] *:xl:w-[60%] *:lg:w-[60%] *:md:w-[70%] *:w-full gap-3' id='projects_bg'>
        <h1 className='capitalize font-main xl:text-[3rem] text-[1.8rem] lg:text-[2rem]  font-semibold text-white'>Carewell and Investment Integrated Solutions Limited.</h1>
        <motion.p variants={zoomInVariants} initial='hidden' whileInView='visible' className='text-white'>Welcome to Carewell and Investment Integrated Solutions Limited. Your Trusted Marketing Partner for Sustainable Solutions
</motion.p>
      </div>
      <div className='flex items-center justify-center py-6 text-center w-full'>
        <motion.h1 variants={zoomInVariants} initial='hidden' whileInView='visible' className='xl:w-[80%] lg:w-[80%] md:w-[80%] w-full px-[2rem]'>As Darobet Nigeria Limited's marketing division, Carewell Investment and Integrated Solutions Limited is committed to promoting a wide range of goods and services that meet your demands. We link you with cutting-edge solutions that improve your lifestyle and financial portfolio with an emphasis on quality and sustainability.</motion.h1>
      </div>
      <div className='flex xl:items-center lg:items-center justify-center flex-col px-[2rem] xl:px-[3rem] lg:px-[3rem] py-8'>
        <h1 className='capitalize font-semibold text-[22px] font-heading text-orange-600 mb-6'>what we do</h1>
        <div className='flex justify-center gap-4 flex-wrap xl:flex-nowrap w-full'>
          {about.map(({img, heading, details,index}) => {
            return (
              <motion.div variants={slideUpVariants} initial='hidden' whileInView='visible' transition={{duration:0.5, delay: index * 0.5}} key={index} className='flex justify-center flex-col gap-3 xl:w-[25%] lg:w-[48%] md:w-[48%] w-full mb-3 xl:mb-0 lg:mb-0 md:mb-0'>
                <img src={img} alt="" className='w-[350px] h-[300px] border-[10px] border-white rounded-[10px] object-cover' id='shadow_img'/>
                <div>
                  <h1 className='capitalize text-[17px] font-semibold text-orange-600'>{heading}</h1>
                  <p className='text-[15px]'>{details}</p>
                </div>
              </motion.div>
            )
          })}
        </div>
      </div>
      <div className='flex items-center justify-center flex-col px-[2rem] xl:px-[3rem] lg:px-[3rem] py-8 bg-gray-100'>
        <h1 className='capitalize font-semibold text-[22px] font-heading text-orange-600 mb-6'>why choose carewell</h1>
        <div className='flex items-start justify-between xl:flex-nowrap lg:flex-nowrap flex-wrap gap-4'>
          {choose.map(({heading,details,icon,index}) => {
            return (
              <motion.div variants={slideUpVariants} initial='hidden' whileInView='visible' transition={{duration:0.5, delay: index * 0.5}} key={index} className='flex items-center flex-col justify-center my-6 gap-2 xl:w-[25%] lg:w-[40%] md:w-[40%] w-full text-center p-8'>
                <span className='text-[50px] text-orange-600 mb-[10px]'>{icon}</span>
                <h1 className='font-semibold uppercase'>{heading}</h1>
                <p>{details}</p>
              </motion.div>
            )
          })}
        </div>
      </div>
      <motion.div variants={slideUpVariants} initial='hidden' whileInView='visible' className='flex items-center justify-center flex-col py-6 px-[2rem] xl:px-[3rem] lg:px-[3rem]'>
        <h1 className='capitalize font-semibold text-[22px] font-heading text-orange-600 mb-6'>Get in touch</h1>
        <p className='text-center'>For inquiries or to learn more about our products and services, reach out to us at:</p>
        <span>Email: <a href="mailto:carewellandinvestment@gmail.com" className='text-color hover:text-orange-600'>carewellandinvestment@gmail.com</a></span>
      </motion.div>
      <div className='flex items-center justify-center py-8 bg-orange-600'>
        <motion.h1 variants={slideUpVariants} initial='hidden' whileInView='visible' className='text-[17px] text-center text-white px-[2rem]'>Join us at Carewell Investment and Integrated Solutions Limited and discover the difference that quality and commitment can make in your investment journey!</motion.h1>
      </div>
    </div>
  )
}

export default Carewell_Investment