import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import teamList from './TeamList'
import { slideUpVariants, zoomInVariants } from '../assets/animation'
import { motion } from 'framer-motion'
import { RiGraduationCapFill } from "react-icons/ri";
import { LiaCertificateSolid } from "react-icons/lia";


const Details = () => {

    const {name} = useParams()
    const teamNames = teamList.find((teamList) => teamList.name === name)
    const {id, position, image, qualification1, qualification2, certification,certification2,certification3, experience} = teamNames
    // console.log(teamNames);
    useEffect (() => {
        document.title = `${name} | Darobet Nigeria Limited`
      },[])
    
  return (
    <div className='flex items-center justify-center  gap-6 flex-col'>
            <div className='xl:h-[50vh] h-[35vh] w-full bg-color xl:text-[9rem] text-[45px] md:text-[90px] flex items-center justify-center font-heading capitalize text-cyan-400 py-3 text-center' id='name'>{name}</div>
            <div className='px-[2rem] xl:px-[3rem] lg:px-[3rem] flex items-center justify-center xl:gap-16 lg:gap-16 gap-4 relative xl:-top-[125px] z-20 -top-[130px] md:-top-[120px] xl:flex-nowrap lg:flex-nowrap flex-wrap'>
                <div>
                    {image ? <img  src={image} alt="" className='object-cover object-center rounded-[10px] w-[300px] h-[340px] border-4 border-white' /> : <h1 className='bg-white rounded-[10px] w-[300px] h-[340px] text-[15rem] uppercase text-color text-center font-heading border-4 border-white'>{name[0]}</h1>}
                </div>
                <div className='flex justify-between flex-col xl:gap-[5.5rem] lg:gap-[5.5rem] gap-3 md:gap-8'>
                    <div className='xl:text-white lg:text-white md:text-white text-color xl:bg-gray-800 xl:px-12 lg:px-12 py-1'>
                        <motion.h1 variants={slideUpVariants} initial='hidden' whileInView='visible' className='xl:text-[35px] md:text-[30px] lg:text-[35px] text-[25px] font-heading capitalize font-semibold'>{name}</motion.h1>
                        <motion.p variants={zoomInVariants} initial='hidden' whileInView='visible' className='capitalize'>{position}</motion.p>
                    </div>
                    {/* <motion.div variants={slideUpVariants} initial='hidden' whileInView='visible'>
                        <h1></h1>
                    </motion.div> */}
                    <div className='flex flex-col xl:gap-8 lg:gap-8 gap-3'>
                    <div>
                        <RiGraduationCapFill className='text-color text-[1.2rem]'/>
                        <motion.ul  variants={zoomInVariants} initial='hidden' whileInView='visible' className='flex flex-col gap-2'>
                            <li className='text-[15px] font-semibold text-black list-disc capitalize break-words'>{qualification1}</li>
                            {qualification2 && <li className='text-[15px] font-semibold text-black list-disc capitalize'>{qualification2}</li>}
                        </motion.ul>
                    </div>
                    {certification ? <div>
                        <LiaCertificateSolid className='text-color text-[1.4rem]'/>
                        <motion.ul  variants={zoomInVariants} initial='hidden' whileInView='visible' className='flex flex-col gap-2'>
                            <li className='text-[15px] font-semibold text-black list-disc capitalize'>{certification}</li>
                            {certification2 && <li className='text-[15px] font-semibold text-black list-disc capitalize'>{certification2}</li>}
                            {certification3 && <li className='text-[15px] font-semibold text-black list-disc capitalize'>{certification3}</li>}
                        </motion.ul>
                    </div> : ''}
                    <h1 className='text-[15px] font-semibold text-color flex flex-col gap-1'>Duration of Service: <span className='text-black'>{experience} years</span></h1>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default Details