import director from'../assets/images/director.jpg'
import chairman from'../assets/images/chairman.jpg'
import director2 from'../assets/images/director2.jpg'
import oladipupo from'../assets/images/bamisaye.jpg'
import Olasunkanmi from'../assets/images/olasunkanmi.jpg'
import ogunmola from '../assets/images/ogunmola.jpg'
import abutu from '../assets/images/abutu.JPG'
import oladele from '../assets/images/oladele.jpg'
import gracious from '../assets/images/gracious.jpg'
import stanley from '../assets/images/stanley.JPG'



const teamList = [
    {
        id: 1,
        name: 'Ayodele Oluwasegun Osuporu',
        position: 'Chairman/Principal Architect',
        image: chairman,
        qualification1: 'HND (Hons) Quantity Surveying',
        certification: 'Member, Nigeria Institute of Quantity Surveyors (MNIQS: 6170)',
        experience: '25',
    },
    {
        id: 2,
        name: 'Olaniyi Oluwadare Osuporu',
        position: 'Director',
        image: director,
        qualification1: 'OND Mechanical Engineering',
        qualification2: 'BSc  (Hons) Political Science',
       
        experience: '25',
    },
    {
        id: 3,
        name: 'Ayodele Damilola Osuporu',
        position: 'director',
        image: director2,
        qualification1: 'OND Marketing',
        qualification2: 'BSc  (Hons) Sociology',
       
        experience: '15',
    },
    {
        id: 4,
        name: 'Bamisaye Oladipupo',
        position: 'Executive Director (Project)',
        image: oladipupo,
        qualification1: 'B.Eng (Hons) Civil Engineering',
        qualification2: 'Master in Environmental Planning & Protection ',
        // certification: 'Fellow, Nigerian Society of Engineers (FNSE)', 
        // certification2: 'Fellow, Nigerian Institution of Civil Engineers (FNICE)',
        // certification3: 'Registered Engineer (R.18,053)',
        // experience: '18',
        certification: 'Fellow, Nigerian Society of Engineers (FNSE)',
        certification2: 'Fellow, Nigerian Institution of Civil Engineers (FNICE)',
        certification3: 'Registered Engineer (R.18,053)',
        experience: '18',
    },
    {
        id: 5,
        name: 'Olasunkanmi Ebenezer Oshuporu',
        position: 'building engineer',
        image: Olasunkanmi,
        qualification1: 'HND Building Engineering',
        certification: 'Member, Nigerian Institute of Builders',
        certification2: 'Registered Member (CORBON)',
        experience: '20',
    },
    {
        id: 6,
        name: 'Ogunmola Oluwakemi Elizabeth',
        position: 'chief quantity surveyor',
        image: ogunmola,
        qualification1: 'B.tech (Hons) Quantity Surveying',
        certification: 'Member, Nigerian Institute of Quantity Surveyors (MNIQS:4590)',
        experience: '10',
    },
    {
        id: 7,
        name: 'abutu matthew',
        position: 'civil engineer',
        image: abutu,
       qualification1: 'B.Eng (Hons) Civil Engineering',
       experience: '18',
    },
    {
        id: 8,
        name: 'orisunmibola oladele',
        position: 'administrative manager',
        image: oladele,
        qualification1: 'HND Business and Administrative Management',
        certification: 'Fellow Member (IPMA)',
        experience: '15'
        
    },
    {
        id: 9,
        name: 'gracious ewoma',
        position: 'account manager',
        image: gracious,
        qualification1: 'BSc (Hons) Accounting',
        certification: 'Member, Chattered Institute of Commerce of Nigeria (CICN)',
        experience: '5'
    },
    {
        id: 10,
        name: 'Stanley igba',
        position: 'electrical engineer',
        image: stanley,
        qualification1: 'technical certificate',
        experience: '15'
    },
    {
        id: 11,
        name: 'gbenga shodipo',
        position: 'technical engineer(steel)',
        image: '',
        qualification1: 'technical certificate (steel fabrication and installation)',
        experience: '15'
    }
    
]

export default teamList