import React from 'react';
import { useSwiper } from 'swiper/react';
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { IoArrowForwardCircleOutline } from "react-icons/io5";

export const SwiperNavButtons = () => {
  const swiper = useSwiper();

  return (
    <div className="py-[10px] text-cyan-400 flex items-center justify-end gap-4">
      <button onClick={() => swiper.slidePrev()}><IoArrowBackCircleOutline className='text-[30px] hover:text-bg'/></button>
      <button onClick={() => swiper.slideNext()}><IoArrowForwardCircleOutline className='text-[30px] hover:text-bg'/></button>
    </div>
  );
};