import forklift from '../assets/images/forklift.jpg'
import forming from '../assets/images/forming machine.jpg'
import moulding from '../assets/images/moulding.jpg'
import lekki1 from '../assets/images/lekki1.jpg'
import lekki2 from '../assets/images/lekki2.jpg'
import lekki3 from '../assets/images/lekki3.jpg'
import lekki4 from '../assets/images/lekki4.jpg'
import owo1 from '../assets/images/owo1.jpg'
import owo2 from '../assets/images/owo2.jpg'
import owo3 from '../assets/images/owo3.jpg'
import owo4 from '../assets/images/owo4.jpg'
import owo5 from '../assets/images/owo5.jpg'
import owo6 from '../assets/images/owo6.jpg'
import apo1 from '../assets/images/apo1.jpg'
import apo2 from '../assets/images/apo2.jpg'
import apo3bd1 from '../assets/images/apo3bd1.jpg'
import apo3bd2 from '../assets/images/apo3bd2.jpg'
import apo4bd1 from '../assets/images/apo4bd1.jpg'
import apo4bd2 from '../assets/images/apo4bd2.jpg'
import ajayi1 from '../assets/images/ajayi1.jpg'
import ajayi2 from '../assets/images/ajayi2.jpg'
import ajayi3 from '../assets/images/ajayi3.jpg'
import kaura1 from '../assets/images/kaura1.jpg'
import kaura2 from '../assets/images/kaura2.jpg'
import kaura3 from '../assets/images/kaura3.jpg'
import ibadan from '../assets/images/ibadan.jpg'
import ibadanvideo from '../assets/videos/terrace ibadan.mp4'







const ProjectsList = [
    {
        name: 'Exclusive 4-bedroom terrace duplex with BQ',
        price: 'competitive and open to outright buyers only',
        location: 'cadastral zone, kaura district, FCT abuja',
        status: 'completed',
        details: 'Proudly present an exclusive opportunity to own a 4-bedroom terrace duplex with BQ, nested in a serene and secure environment. Mini estate built by Darobet Nigeria Limited.',
        media:
        [
            kaura1,
                  kaura2,
                  kaura3 
    ],
        
        features: [
                {heading: 'Prime Location', details: 'Situated in the sought-after kaura district , with easy access to key landmarks such as Central Business District, Shopping Malls, top school and Hospital.'},
                {heading: 'Mordern Design', details: 'Aesthetic, well-ventilated duplex design to exude elegance and comfort.'},
                {heading: 'Spacious Layout', details: 'Each unit features 4-large en-suite bedroom, an additional BQ ample living space.'},
                {heading:  'Quality Finishes', details: 'Premium materials and stylish finishes throughout.'},
                {heading:  'Secure Environment', details: 'Gated mini-estate with 24-hour security and controlled access.'},
                {heading:  'Ample Packing', details: 'Each unit offers generous parking space for multiple vehicles.'},
                {heading: 'Utilities and Infrastructure', details: 'Fully serviced estate with constant water supply and paved roads.'}  
        ]
        
    },
    {
        name: 'Exclusive 3-bedrooms twin duplex with inbuilt BQ',
        price: 'competitive and open to outright buyers only',
        location: 'lekki, ajayi apata, lagos state',
        status: 'ongoing',
        // details: 'Proudly present an exclusive opportunity to own a 4-bedroom terrace duplex with BQ, nested in a serene and secure environment. Mini estate built by Darobet Nigeria Limited.',
        media:
        [
            lekki1,
            lekki2,
            lekki3,
            lekki4
               
    ],

        // features: [
        //         {heading: 'Prime Location', details: 'Situated in the sought-after kaura district , with easy access to key landmarks such as Central Business District, Shopping Malls, top school and Hospital.'},
        //         {heading: 'Mordern Design', details: 'Aesthetic, well-ventilated duplex design to exude elegance and comfort.'},
        //         {heading: 'Spacious Layout', details: 'Each unit features 4-large en-suite bedroom, an additional BQ ample living space.'},
        //         {heading:  'Quality Finishes', details: 'Premium materials and stylish finishes throughout.'},
        //         {heading:  'Secure Environment', details: 'Gated mini-estate with 24-hour security and controlled access.'},
        //         {heading:  'Ample Packing', details: 'Each unit offers generous parking space for multiple vehicles.'},
        //         {heading: 'Utilities and Infrastructure', details: 'Fully serviced estate with constant water supply and paved roads.'}  
        // ]
        
    },
    {
        name: 'Exclusive 4-bedrooms bungalow with 2-bedroom BQ',
        price: 'competitive and open to outright buyers only',
        location: 'owo, ondo state',
        status: 'completed',
        // details: 'Proudly present an exclusive opportunity to own a 4-bedroom terrace duplex with BQ, nested in a serene and secure environment. Mini estate built by Darobet Nigeria Limited.',
        media:
        [
            owo1,
            owo2,
            owo3,
            owo4,
            owo5,
            owo6
               
    ],
},


{
    name: 'Newly built 4-bedroom flat terrace duplex',
    price: 'competitive and open to outright buyers only',
    location: 'plot 28, Block 1A, Oke-ibadan Estate, akala way, akobo, Ibadan',
    status: 'completed',
    details: 'Spacious and mordern 4-bedroom home that offer perfect blend of luxury and comfort',
    media:
    [
        ibadan
           
],
vid: ibadanvideo, 

 features: [
                {heading: 'Mordern Design', details: 'Aesthetic, well-ventilated duplex design to exude elegance and comfort.'},
                {heading: 'Spacious Layout', details: 'Each unit features 4-large en-suite bedroom, an additional BQ ample living space.'},
                {heading:  'Quality Finishes', details: 'Premium materials and stylish finishes throughout.'},
                {heading:  'Secure Environment', details: 'Gated mini-estate with 24-hour security and controlled access.'},
                {heading:  'Ample Packing', details: 'Each unit offers generous parking space for multiple vehicles.'},
                {heading: 'Utilities and Infrastructure', details: 'Fully serviced estate with constant water supply and paved roads.'}  
        ]
},
{
    name: 'Shopping Complex',
    price: 'competitive and open to outright buyers only',
    location: 'apo resettlement, abuja',
    status: 'ongoing',
    // details: 'Proudly present an exclusive opportunity to own a 4-bedroom terrace duplex with BQ, nested in a serene and secure environment. Mini estate built by Darobet Nigeria Limited.',
    media:
    [
        apo1,
        apo2,
       
           
],

},
{
    name: '3-bedroom twin duplex with BQ',
    price: 'competitive and open to outright buyers only',
    location: 'apo resettlement, abuja',
    status: 'completed',
    // details: 'Proudly present an exclusive opportunity to own a 4-bedroom terrace duplex with BQ, nested in a serene and secure environment. Mini estate built by Darobet Nigeria Limited.',
    media:
    [
        apo3bd1,
        apo3bd2,
       
           
],
},
{
    name: '4-bedroom semi-detached duplex',
    price: 'competitive and open to outright buyers only',
    location: 'apo resettlement, abuja',
    status: 'completed',
    // details: 'Proudly present an exclusive opportunity to own a 4-bedroom terrace duplex with BQ, nested in a serene and secure environment. Mini estate built by Darobet Nigeria Limited.',
    media:
    [
        apo4bd1,
        apo4bd2,
       
           
],
},
{
    name: '2-bedroom and 1-bedroom apartments',
    price: 'competitive and open to outright buyers only',
    location: 'lekki ajayi, apata, lagos',
    status: 'completed',
    // details: 'Proudly present an exclusive opportunity to own a 4-bedroom terrace duplex with BQ, nested in a serene and secure environment. Mini estate built by Darobet Nigeria Limited.',
    media:
    [
        ajayi1,
        ajayi2,
        ajayi3
       
           
],
},
{
    name: 'Moulding machine',
    price: 'competitive and open to outright buyers only',
    // location: 'lekki ajayi, apata, lagos',
    status: '',
    details: 'Perfectly working moulding machine',
    media:
    [
        moulding
       
           
],
},
{
    name: 'forklift truck',
    price: 'competitive and open to outright buyers only',
    // location: 'lekki ajayi, apata, lagos',
    status: '',
    details: 'A perfectly working Forklift truck(Isuzu), manual transmission, diesel fueling, 30 tonnes',
    media:
    [
        forklift
       
           
],
},
{
    name: 'aluminium forming machine',
    price: 'competitive and open to outright buyers only',
    // location: 'lekki ajayi, apata, lagos',
    status: '',
    details: 'Perfectly working Aluminium roof forming machine. Only for long span roofing sheets.',
    media:
    [
        forming
       
           
],
},

        
]

export default ProjectsList