import React, { useEffect } from 'react'
import logo from '../assets/images/DAROBET WEBSITE.pdf-image-020.jpg'
import logo2 from '../assets/images/DAROBET WEBSITE.pdf-image-023.jpg'
import logo3 from '../assets/images/DAROBET WEBSITE.pdf-image-026.jpg'
import logo4 from '../assets/images/DAROBET WEBSITE.pdf-image-006.jpg'
import pic1 from '../assets/images/1.avif'
import pic2 from '../assets/images/2.jpg'
import pic3 from '../assets/images/3.webp'
import pic5 from '../assets/images/4.jpg'
import { motion } from 'framer-motion'
import { slideUpVariants, zoomInVariants } from '../assets/animation'
import { MdArrowOutward } from "react-icons/md";
import pic4 from '../assets/images/team.jpeg'
import { useNavigate } from 'react-router-dom'


const Home = () => {
  useEffect (() => {
    document.title = 'Home | Darobet Nigeria Limited'
  },[])
 
  const navigate = useNavigate() 
  const handleNav = () => {
    navigate('/contact')
  }
  const handleTeamNav = () => {
    navigate('/team')
  }
  return (
    <div className=''>
      <div className='flex flex-col items-left xl:py-[10rem] lg:py-[10rem] py-[6rem] xl:px-[3rem] lg:px-[3rem] px-[2rem]' id='home'>
      <motion.h1 variants={slideUpVariants} initial='hidden' whileInView='visible' className='flex items-center gap-3 font-heading text-bg text-left' id='headings'>We build <p className='text-orange-600'>Trust.</p></motion.h1>
      <motion.h1 variants={slideUpVariants} initial='hidden' whileInView='visible' className='flex items-center gap-3 font-heading text-bg text-left' id='headings'>We build <p className='text-orange-600'>Dreams.</p></motion.h1>
      <motion.h1 variants={zoomInVariants} initial='hidden' whileInView='visible' className='xl:w-[45%] text-[14px] xl:text-[18px] lg:text-[17px] md:text-[16px] mt-4 font-semibold'>Darobet Nigeria Limited (DNL) (RC 137955) is a civil engineering and construction company registered to carry out the construction of roads and buildings throughout the federation of Nigeria. The company was incorporated on September 1989 and has since been involved in the execution of various construction projects in Nigeria.</motion.h1>
      </div>
      <motion.div variants={slideUpVariants} initial='hidden' whileInView='visible' className='flex items-center xl:justify-around xl:px-[3rem] lg:px-[3rem] px-[2rem] py-4 bg-gray-100 flex-wrap xl:flex-nowrap lg:flex-nowrap md:flex-nowrap'>
        <div className='xl:w-[40%] w-full flex flex-col gap-2'>
          <p variants={zoomInVariants} initial='hidden' whileInView='visible' className='uppercase xl:text-[3.5rem] lg:text-[3.5rem] text-[2.5rem] font-heading text-transparent stroke-cyan-400' id='stroke'>history</p>
          <p variants={slideUpVariants} initial='hidden' whileInView='visible' className='text-[17px]'>Darobet started out as a construction company 2002 and over the years have developed the road construction and infrastructural repairs, project management and construction of Aluminum Roofing sheets. <span>This is as a request from clients due to the exceptional feedbacks in our offerings and result in our projects.</span></p>
          <p variants={slideUpVariants} initial='hidden' whileInView='visible' className='text-[17px]'>Quality without compromise is our commitment one based on a solid work ethic as demonstrated by our experienced personnel. Our staffs consist of degreed and registered professional in the areas of estimating, project management, field operations and accounting.</p>
        </div>
        <div className='xl:w-[50%] lg:w-[50%] w-full flex flex-col gap-1 items-center justify-center p-2 flex-wrap mt-4 xl:mt-0 lg:mt-0'>
          <div className='flex items-center gap-1 border-4 border-white'>
            <img src={logo} alt="darobet concrete barrier" className='xl:w-[300px] h-[250px] lg:w-[300px] w-[50%] object-cover object-center'/>
            <img src={logo2} alt="darobet concrete barrier" className='xl:w-[300px] lg:w-[300px] h-[250px] w-[50%] object-cover object-center'/>
          </div>
          <div className='flex items-center gap-1 border-4 border-white'>
            <img src={logo3} alt="darobet concrete barrier" className='xl:w-[300px] lg:w-[300px] h-[250px] w-[50%] object-cover object-center'/>
            <img src={logo4} alt="darobet concrete barrier" className='xl:w-[300px] lg:w-[300px] h-[250px] w-[50%] object-cover object-center'/>
          </div>
        </div>
      </motion.div>
      <div className='flex justify-evenly bg-gray-100 xl:px-[3rem] lg:px-[3rem] px-[2rem] py-10 flex-wrap lg:flex-nowrap xl:flex-nowrap md:flex-nowrap'>
        <motion.div variants={zoomInVariants} initial='hidden' whileInView='visible' className='flex flex-col gap-3'>
          <h1  className='uppercase xl:text-[2.5rem] lg:text-[2.5rem] text-[1.5rem] font-heading text-transparent stroke-cyan-400' id='stroke'>our mission</h1>
            <ul variants={slideUpVariants} initial='hidden' whileInView='visible' className='flex flex-col gap-2 xl:w-[70%] lg:w-[70%] w-full list-disc'>
              <li>We are to provide qualitative housing at affordable cost to the generality of people.</li>
              <li>To carry out the business of construction and engineering service in a faster but efficient manner, cheaper yet meeting all client requirements and better meeting all quality standards quality.</li>
            </ul>
        </motion.div>
        <motion.div variants={zoomInVariants} initial='hidden' whileInView='visible' className='flex flex-col gap-3 xl:mt-0 lg:mt-0 mt-4'>
          <h1  className='uppercase xl:text-[2.5rem] lg:text-[2.5rem] text-[1.5rem] font-heading text-transparent stroke-cyan-400' id='stroke'>our offer</h1>
          <p>We offer public and private experience plus excellent professionalism and quality services. Our competitive edge is attributed to:</p>
          <ul variants={zoomInVariants} initial='hidden' whileInView='visible' className='flex flex-col gap-2 list-disc'>
            <li>High Skills</li>
            <li>Affordable Price</li>
            <li>Home Grown Expertise</li>
          </ul>
        </motion.div>
      </div>
      <div className='bg-bg xl:px-[3rem] lg:px-[3rem] px-[2rem] text-white py-8 flex items-center flex-col gap-4'>
        <div className='flex items-center justify-between w-full gap-4 xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap'>
        <motion.div variants={slideUpVariants} initial='hidden' whileInView='visible' className='flex flex-col gap-6 xl:w-[40%] lg:w-[40%] w-full'>
          <h1 variants={slideUpVariants} initial='hidden' whileInView='visible' className='uppercase xl:text-[2rem] lg:text-[2rem] text-[1.5rem] font-heading text-transparent' id='strokes'>Our service</h1>
          <p variants={slideUpVariants} initial='hidden' whileInView='visible'>DNL construction activity focuses basically on the development of all types of Civil Works and Building infrastructures through construction projects for both public and private clients.</p>
          <p variants={slideUpVariants} initial='hidden' whileInView='visible'>We carry out projects under concession arrangements through various public-private partnership contract models, covering the whole concession business value chain, from project design to financing, construction, commissioning and operation.</p>
          <p variants={slideUpVariants} initial='hidden' whileInView='visible'>Improving society, generating wealth to guarantee the well-being of the citizens it serves, in the final analysis, is a primordial part of DNL mission.</p>
          <ul variants={slideUpVariants} initial='hidden' whileInView='visible' className='flex flex-col list-disc *:text-cyan-400'>
            <li>Construction service including infrastructure repairs.</li>
            <li>Fabrication of aluminum roofing sheet.</li>
            <li>Engineering service including road maintenance</li>
            <li>Project management</li>
          </ul>
          <button className='text-[15px] border-2 border-cyan-400 rounded-[20px] flex items-center gap-2 text-center px-4 py-2 w-[150px] justify-center flex-row' onClick={handleNav}><a className='flex items-center justify-center gap-2 hover:text-cyan-400'>Learn More <MdArrowOutward className='text-cyan-400 animate-pulse'/></a></button>
        </motion.div>
        <div className='columns-1 sm:columns-2 lg:columns-2 xl:columns-2 gap-2 row-2 xl:w-[50%] lg:w-[50%] w-full *:border-[6px] *:border-white'>
            <motion.img variants={slideUpVariants} initial='hidden' whileInView='visible' src={pic1} alt="" className='w-full object-cover mb-2'/>
            <motion.img variants={slideUpVariants} initial='hidden' whileInView='visible' src={pic3} alt="" className='w-full object-cover'/>
            <motion.img variants={slideUpVariants} initial='hidden' whileInView='visible' src={pic2} alt="" className='w-full object-cover mt-2 xl:mt-0 lg:mt-0 md:mt-0'/>
            <motion.img variants={slideUpVariants} initial='hidden' whileInView='visible' src={pic5} alt="" className='w-full object-cover mt-2 h-[258px]'/>
        </div>
        </div>
        
      </div>
      <div className='flex items-center justify-between xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap w-full'>
        <div className='xl:px-[3rem] lg:px-[3rem] px-[2rem] xl:py-0 lg:py-0 py-4 xl:w-[50%] lg:w-[50%] md:w-[50%] flex flex-col gap-4'>
          <motion.h1 variants={zoomInVariants} initial='hidden' whileInView='visible' className='uppercase xl:text-[2.5rem] lg:text-[2.5rem] text-[1.5rem] font-heading text-transparent stroke-cyan-400' id='stroke'>the team</motion.h1>
          <motion.p variants={slideUpVariants} initial='hidden' whileInView='visible'>Quality without compromise is our commitment one based on a solid work ethic as demonstrated by our experienced personnel. Our staffs consist of home-grown  degreed and registered professional in the areas of estimating, project management, field operations and accounting.</motion.p>
          <button className='text-[15px] border-2 border-cyan-400 rounded-[20px] flex items-center gap-2 text-center px-4 py-2 w-[150px] justify-center flex-row hover:bg-color mt-4 hover:text-white' onClick={handleTeamNav}><a className='flex items-center justify-center gap-2 hover:text-white'>Learn More<MdArrowOutward className='text-cyan-400 animate-pulse'/></a></button>
        </div>
        <img src={pic4} alt="team" className='xl:w-[50%] xl:max-w-[50%] md:max-w-[50%] lg:max-w-[50%]'/>
      </div>
    </div>
  )
}

export default Home