import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './component/Layout.jsx';
import Home from './pages/Home'
import About from './pages/About';
import Team from './pages/Team';
import OurServices from './pages/OurServices.jsx';
import Contact from './pages/Contact';
import Details from './pages/Details';
import ScrollToTop from './component/ScrollToTop';
import ProjectDetails from './carewell component/ProjectDetails.jsx'
import Carewell_Investment from './carewell component/Carewell_Investment.jsx';
import Projects from './carewell component/Projects.jsx'
import Services from './carewell component/Services.jsx';
import OurProjects from './pages/OurProjects.jsx';


function App() {
  return (
    <div className='font-text'>
      <ScrollToTop/>
    <Routes>
      <Route path='' element={<Layout/>}>
        <Route path='/' element={<Home/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/team' element={<Team/>}/>
        <Route path='/team/:name' element={<Details/>}/>
        <Route path='/ourprojects' element={<OurProjects/>}/>
        {/* <Route path='/projects/:name' element={<ProjectDetails/>}/> */}
        <Route path='/ourservices' element={<OurServices/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/carewell_investment' element={<Carewell_Investment/>}/>
        <Route path='/carewell_investment/services' element={<Services/>}/>
        <Route path='/carewell_investment/projects/:name' element={<ProjectDetails/>}/>
        <Route path='/carewell_investment/projects' element={<Projects/>}/>
      </Route>
    </Routes>
    </div>
  );
}

export default App;
