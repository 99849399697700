import React, { useEffect } from 'react';
import ProjectList from '../carewell component/ProjectList'
import logo from '../assets/images/logo.png'


const Projects = () => {
  useEffect(() => {
    document.title = 'Projects | Carewell and Investment Integrated Solutions'
  }, [])
  return (
    <div>
      <div className='flex item-center justify-center xl:px-[3rem] lg:px-[3rem] px-[2rem] xl:h-[70vh] lg:h-[70vh] h-[50vh]' id='project_bg'>
        <h1 className='flex items-center text-[3rem] uppercase text-white tracking-[10px] font-heading'>projects.</h1>
      </div>
      <div className='flex items-center justify-between px-[2rem] xl:px-[3rem] lg:px-[3rem] py-6 xl:flex-nowrap lg:flex-nowrap flex-wrap md:flex-nowrap gap-3 bg-gray-100'>
        <h1 className='font-semibold font-text'>Available listings you may like:</h1>
        {/* <div>
          <span className='h-[40px] xl:w-[400px] lg:w-[400px] w-full flex items-center justify-between border-2 border-bg pl-2 rounded-[4px]'>
          <input type="text" placeholder='search name...'  className='focus:outline-none w-full text-[14px] bg-transparent'/>
          <IoSearchSharp className='bg-color text-white h-full px-[10px] text-[40px] hover:bg-opacity-90 cursor-pointer'/>
          </span>
        </div> */}
      </div> 
      <ProjectList/>
    </div>
  )
}

export default Projects