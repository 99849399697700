import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ProjectsList from '../carewell component/ProjectsList'
import { motion } from 'framer-motion'
import { slideUpVariants } from './animation'
import { FaWhatsapp } from "react-icons/fa";
import {Carousel} from '../component/Carousel'
import { CiLocationOn } from "react-icons/ci";
import { DefaultPlayer as Video } from 'react-html5video'
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import  emailjs from '@emailjs/browser'


  const ProjectDetails = () => {
  const navigate = useNavigate()
  const [error, setError] = useState(null)
  const {name} = useParams()
  const [names, setNames] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [success, setSuccess] = useState(null)

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    if(email === '' || names === '' || message === '' || phone === ''){
      setError('All fields are mandatory')
  }else {

    emailjs
      .sendForm(
        "service_67l0dl8",
        "template_lg5hbdp",
        form.current,
        {
          publicKey: 'B9s6oEG5bo-nAfp9H'
        }
      )
      .then(
        () => {
          setEmail('')
          setMessage('')
          setPhone('')
          setNames('')
          // console.log("message sent");
          setSuccess('Message Sent Successfully')
        },
        (error) => {
          console.log(error.text);
        }
      );
  }};

  const [show, setShow] = useState(false)
    const ProjectsInfo = ProjectsList.find((ProjectsList) => ProjectsList.name === name)
    const {media,vid, details, location,status, features} = ProjectsInfo

    useEffect(() => {
      document.title = `${name} | Carewell and Investment Integrated Solutions`
    }, [])

    const transitionNav = () => {
      if(window.scrollY > 60) {
        setShow(true)
      }else{
        setShow(false)
      }
     }
  
     useEffect(() => {
      window.addEventListener('scroll', transitionNav)
      return () => window.removeEventListener('scroll',transitionNav)
     },[])

    //  console.log(media);
     
  return (
    <div>
      <div className='flex items-center flex-col'>
      {/* <div className='flex items-center justify-center h-[20vh] bg-color w-full'>
        <h1 className='font-heading text-[2rem] text-white text-center'>Featured Project</h1>
      </div> */}
      <div className='flex justify-between items-center gap-2 w-full px-[2rem] xl:px-[3rem] lg:px-[3rem] my-5'>
            <div className='flex flex-col gap-1'>
            <h1 className='cursor-pointer flex items-center gap-1 p-1 bg-cyan-50 w-fit rounded-[5px] hover:bg-cyan-400 text-[14px]' onClick={() => navigate(-1)}><MdKeyboardDoubleArrowLeft className='text-[22px] text-orange-600'/>Back</h1>
              <h1 className='xl:text-[1.6rem] lg:text-[1.6rem] text-[1.2rem] capitalize font-semibold text-bg'>{name}</h1>
              <div className='flex xl:gap-3 lg:gap-3 gap-1 *:w-fit *:px-[8px] *:py-[3px] *:text-white *:text-center *:uppercase *:text-[8px] *:xl:text-[14px] *:lg:text-[14px] *:md:text-[14px] *:rounded-[4px]'>
                <span className='bg-green-700'>featured</span>
                <span className='bg-color'>for sale</span>
                <span className='bg-red-700'>hot offer</span>
              </div>
              <p className='capitalize xl:text-[1rem] lg:text-[1rem] text-[14px] text-gray-700 font-normal flex items-center gap-1'><CiLocationOn className='text-orange-500'/>{location}</p>
            </div>
            <h1 className='xl:text-[1.15rem] lg:text-[1.1rem] text-[12px] font-semibold uppercase bg-gray-50 shadow-md py-[2px] px-[5px] rounded-[20px] w-fit h-fit border-2'>{status === 'completed' ? <span className='text-green-700'>{status}</span> : <span className='text-orange-500'>{status}</span>}</h1>
          </div>
      <div className='flex items-start gap-5 w-full px-[2rem] xl:px-[3rem] lg:px-[3rem] flex-wrap xl:flex-nowrap lg:flex-nowrap md:flex-nowrap'>
          <div className='flex justify-center flex-col gap-3 xl:w-[50%] lg:w-[50%] w-full'>
            {/* <img src={src} alt=""  className='rounded-lg xl:h-[70vh] lg:h-[60vh] h-[40vh] object-cover object-center w-full'/> */}
            <Carousel className='w-full' data={media}>
            </Carousel>
            <div className='flex flex-col gap-1'>
              <h1 className='font-semibold capitalize text-bg xl:text-[1.2rem] lg:text-[1.2rem] text-[1rem]'>{name}</h1>
              <p className='text-[15px] mb-2'>{details}</p>
              {features &&
              <div className='flex flex-col'>
                <h1 className='uppercase text-orange-600 font-semibold text-[17px] mb-2'>key features:</h1>
                <div className='border-2 border-gray-300 p-2 rounded-[5px]'>
              {features?.map(({heading, details, index}) => {
                return (
                  <motion.div variants={slideUpVariants} initial='hidden' whileInView='visible' transition={{duration:0.5, delay: index * 0.5}} key={index} className='mb-2'>
                    <h1 className='capitalize text-bg font-semibold'>{heading}:</h1>
                    <p className='text-[15px]'>{details}</p>
                  </motion.div>
                )
              })}
              </div>
              </div>}
            </div>
            {vid && 
            <div className='xl:h-[70vh] lg:h-[60vh] h-[40vh] object-cover object-center w-full p-2 border-2 border-gray-200'>
              <Video loop autoplay>
                <source src={vid}/>
              </Video>
            </div>}
          </div>
          <div className={`px-0 flex justify-center items-center gap-1 flex-col xl:w-[50%] lg:w-[50%] w-full ${show && 'xl:fixed lg:fixed md:fixed right-0 xl:top-[85px] lg:top-[85px] md:top-[85px] mx-auto xl:mx-0 lg:mx-0 md:mx-0 p-4 xl:px-0 lg:px-0'} transition ease-in-out duration-100 delay-50`}>
            <h3 className='text-orange-500 text-left mb-1'>Contact Information:</h3>
            <motion.div variants={slideUpVariants} initial='hidden' whileInView='visible' className='xl:p-6 lg:p-6 p-0 xl:w-[70%] lg:w-[70%] w-full' id='details-bg'>
            <form ref={form} action="" className='flex justify-center gap-3 flex-col *:border-2 *:border-gray-600 *:indent-2 *:rounded-[5px] *:focus:border-color *:focus:outline-none mb-3 *:transition ease-in-out duration-100 delay-50 bg-white'>
              <input type="text" name='from_name' placeholder='Name' onChange={(e) => setNames(e.target.value)} className='h-[40px] xl:h-[50px] lg:h-[50px]'/>
              <input type="number" name='phone' placeholder='Phone' onChange={(e) => setPhone(e.target.value)} className='h-[40px] xl:h-[50px] lg:h-[50px]'/>
              <input type="email" name='from_name' placeholder='Email' onChange={(e) => setEmail(e.target.value)} className='h-[40px] xl:h-[50px] lg:h-[50px]'/>
              <textarea name="message" id="" onChange={(e) => setMessage(e.target.value)}  value={`Hello, I am interested in ${name}`} className='h-[100px]'></textarea>
            </form>
            {error && <h1 className='text-red-600 mb-1 text-[17px]'>{error}</h1>}
            {success && <h1 className='text-green-900 py-1 text-[17px] mb-1'>{success}</h1>}
            <div className='flex items-center justify-evenly gap-3 *:bg-color *:px-[5px] *:py-[10px] *:text-white *:capitalize *:rounded-md *:w-[50%] *:text-[14px] *:transition ease-in-out duration-400 delay-50'>
              <button className='hover:bg-orange-600' onClick={sendEmail}>send message</button>
              <a href="https://wa.me/2348055550436" className='flex items-center gap-1 justify-center hover:bg-orange-600 w-fit'><button className='flex items-center gap-1 justify-center'><FaWhatsapp className='text-[20px] text-green-400'/>WhatsApp</button></a>
            </div>
            </motion.div>
          </div>
      </div>
      <div variants={slideUpVariants} initial='hidden' whileInView='visible' className='flex items-center justify-center flex-col py-8 px-[2rem] xl:px-[3rem] lg:px-[3rem]'>
        {/* <h1 className='capitalize font-semibold text-[22px] font-heading text-orange-600 mb-6'>Get in touch</h1> */}
        <p className='text-center'>For inquiries or to learn more about our products and services, reach out to us at:</p>
        <span>Email: <a href="mailto:carewellandinvestment@gmail.com" className='text-color hover:text-orange-600'>carewellandinvestment@gmail.com</a></span>
      </div>
      </div>
    </div>
  )
}

export default ProjectDetails