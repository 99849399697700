import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import logo from '../assets/images/logo.png'
import { MdMail } from "react-icons/md";
import { AnimatePresence, motion, MotionConfig } from 'framer-motion';
import { IoCloseSharp } from 'react-icons/io5';
import { GiHamburgerMenu } from 'react-icons/gi';


const Nav_Link = [
    {
        path: '/carewell_investment/projects',
        display: 'Projects'
    },
   {
    path: '/carewell_investment/services',
    display: 'Our Service'
   }
  
  ]
const Header = () => {
    const [activeNav, setActiveNav] = useState('')
    const [mobileNav, setMobileNav] = useState(false);
    const [show, setShow] = useState(false)

    const toggleMobileNav = () => {
      setMobileNav(!mobileNav);
    };
    const toggleActive = () => {
      setActiveNav(!activeNav)
      setMobileNav(false)
     }

     const transitionNav = () => {
      if(window.scrollY > 80) {
        setShow(true)
      }else{
        setShow(false)
      }
     }
  
     useEffect(() => {
      window.addEventListener('scroll', transitionNav)
      return () => window.removeEventListener('scroll',transitionNav)
     },[])
  
  
  return (
    <div className={`flex items-center justify-between px-[1rem] xl:px-[2rem] lg:px-[2rem] border-b-2 ${show && 'fixed left-0 right-0 top-0 bg-white transition transform ease-out delay-150 duration-200 shadow-lg z-[99999]'}`}>
          <div>
            <Link to='/carewell_investment' className='flex items-center'>
              <img src={logo} alt="carewell and investment solution" className='xl:w-[80px] lg:w-[80px] md:w-[80px] w-[60px]'/>
              <div className='flex flex-col font-semibold text-bg *xl:text-[14px] *:lg:text-[14px] *:text-[10px] leading-none xl:leading-tight lg:leading-tight *:font-header'>
                <h1 className=''>carewell and investment</h1>
                <span className=''>integrated solutions</span>
                <span className=''>limited</span>
              </div>
            </Link>
          </div>
          <div className='flex item-center justify-center gap-3'>
          <ul className='items-center hidden xl:flex lg:flex md:flex'>
            {Nav_Link.map((item, index) => (
              <li className='hover:bg-color text-bg hover:text-white py-[5px] px-[8px] hover:rounded-[5px] text-[14px] uppercase font-medium font-text transition ease-in-out duration-300 delay-0 border-r-2' key={index}>
                <NavLink to={item.path} className={`activeNav === '#' ? 'actives' : '' transition ease-out duration-300 delay-0`} onClick={() => setActiveNav(!activeNav)}>{item.display}</NavLink>
              </li>
            ))}
          </ul>
          <span className='hidden xl:flex lg:flex md:flex items-center gap-2'><MdMail/> <a href="mailto:carewellandinvestment@gmail.com" className='text-color hover:text-orange-600 text-[15px]'>carewellandinvestment@gmail.com</a></span>
            </div>


            <motion.button
          className='block items-center xl:hidden lg:hidden md:hidden'
          initial="hide"
					animate={mobileNav ? "show" : "hide"}
					onClick={toggleMobileNav}
          >
            {mobileNav ? <IoCloseSharp className='text-[25px] hover:text-[28px] bg-color text-white rounded-full hover:p-[2px]'/> :  <GiHamburgerMenu className='text-[30px] bg-color text-white rounded-full p-[5px]'/>}</motion.button>
          <AnimatePresence>
					{mobileNav && (
						<MotionConfig
							transition={{
								type: "spring",
								bounce: 0.1,
							}}
						>
          <motion.div
          key="mobile-nav"
          variants={{
            hide: {
              y: "100%",
              transition: {
                type: "spring",
                bounce: 0.1,
                when: "afterChildren",
                staggerChildren: 0.25,
              },
            },
            show: {
              y: "0%",
              transition: {
                type: "spring",
                bounce: 0.1,
                when: "beforeChildren",
                staggerChildren: 0.25,
              },
            },
          }}
          initial="hide"
          animate="show"
          exit="hide"
          className='fixed left-0 right-0 top-[50px] bg-color z-50 h-[100vh] flex items-center flex-col gap-[90px] xl:hidden lg:hidden md:hidden'
          >
            <motion.ul
            variants={{
              hide: {
                y: "25%",
                opacity: 0,
              },
              show: {
                y: "0%",
                opacity: 1,
              },
            }}
            className='items-center flex flex-col py-[40px] gap-[25px]'>
              {Nav_Link.map((item, index) => (
                <li className='hover:text-black text-white px-[5px] py-[3px] flex-col rounded-sm text-[16px] capitalize font-medium font-text transition ease-in-out duration-300' key={index}>
                  <NavLink to={item.path} className={activeNav === '#' ? 'active' : ''} onClick={toggleActive}>{item.display}</NavLink>
                </li>
              ))}
            </motion.ul>
            <motion.div
									variants={{
										hide: {
											y: "25%",
											opacity: 0,
										},
										show: {
											y: "0%",
											opacity: 1,
										},
									}}
									className="w-[90%] h-px bg-white/30"
								></motion.div>
                <motion.ul
									variants={{
										hide: {
											y: "25%",
											opacity: 0,
										},
										show: {
											y: "0%",
											opacity: 1,
										},
									}}
									className="list-none flex justify-center gap-x-4"
								>
									{/* <li>
                    <a href=""><FaFacebook className='text-white text-[20px]'/></a>
									</li>
									<li>
                    <a href=""><FaLinkedinIn className='text-white text-[20px]'/></a>
									</li>
									<li>
                    <a href=""><FaSquareXTwitter className='text-white text-[20px]'/></a>
									</li> */}
								</motion.ul>
          </motion.div>
          </MotionConfig>
          )}
        </AnimatePresence>
    </div>
  )
}

export default Header