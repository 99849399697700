import React, { useEffect } from 'react'
import teamList from '../pages/TeamList'
import { Link } from 'react-router-dom'
import {slideUpVariants, zoomInVariants } from '../assets/animation'
import { motion } from 'framer-motion'
import { FaLongArrowAltRight } from "react-icons/fa";


const Team = () => {
  useEffect (() => {
    document.title = 'The Team | Darobet Nigeria Limited'
  },[])
  return (
    <div className=''>
      <div className='flex items-start justify-center flex-col xl:h-[70vh] lg:h-[60vh] h-[50vh]' id='team_bg'>
        <motion.h5 variants={zoomInVariants} initial='hidden' whileInView='visible' className='text-color xl:mx-[3rem] lg:mx-[3rem] mx-[2rem] text-[1.5rem] font-heading border-l-4 border-white px-2'>The team</motion.h5>
        <motion.h1 variants={slideUpVariants} initial='hidden' whileInView='visible' className='text-white xl:px-[3rem] lg:px-[3rem] px-[2rem] xl:text-[3.5rem] lg:text-[3.5rem] text-[2rem] font-heading'>Meet our experts in diverse areas</motion.h1>        
      </div>
      <p className='xl:text-[1.1rem] lg:text-[1.1rem] text-[1rem] text-center py-4 px-[2rem] xl:px-[3rem] lg:px-[3rem] text-color pl-3'>The Management Team is supported by highly trained professionals and technical staffs. They include the following: </p>
      <div className='flex items-center justify-around gap-4 xl:px-[3rem] lg:px-[3rem] px-[2rem] flex-wrap xl:py-16 lg:py-16 py-5 bg-gray-100'>
        {teamList.map(({id, name, position, image, index}) => {
          return (
            <motion.div variants={slideUpVariants} initial='hidden' whileInView='visible' transition={{duration:0.5, delay: index * 0.5}} className=''>
              <Link  to={`/team/${name}`} key={id} className='py-[2px] relative'>
                <div className=''>
                  {image ? <img src={image} alt={name} className='w-[300px] h-[340px] object-center object-cover hover:scale-105 transition ease-in-out duration-500 delay-200 border-4 border-white rounded-[15px] '/> : <h1 className='w-[300px] h-[340px] text-[15rem] uppercase text-color text-center font-heading border-4 border-white rounded-[15px]'>{name[0]}</h1>}
                  {/* <h1>details</h1> */}
                </div>
                {/* <FaLongArrowAltRight className='absolute bg-cyan-400 text-white text-[50px] p-[5px] bottom-[325px] left-[1px]'/>  */}
                <div className='py-3 flex flex-col gap-[2px]'>
                  <h1 className='font-heading text-bg text-[20px] font-semibold capitalize'>{name}</h1>
                  <p className='text-gray-400 capitalize '>{position}</p>
                </div>
              </Link>
              </motion.div>
          )
        })}
      </div>
    </div>
  )
}

export default Team